// svg path {
//   fill: inherit !important;
//   stroke: inherit !important;
// }

.icon {
    fill: rgba($white, 0);
    max-width: 1rem;
    max-height: 1rem;

    &-inline {
        display: inline-flex;
    }

    &-small {
        height: 0.625rem;
        width: 0.625rem;
    }

    &-table {
        margin: auto 1.25rem;
        display: table-cell;
        vertical-align: middle;

        svg {
            vertical-align: middle;
        }
    }

    &-fixed {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    &-block {
        margin: 0 auto;
        display: block;
    }

    &-medium {
        height: 2rem;
        width: 2.5rem;
        max-width: none;
        max-height: none;
    }

    &-escda {
        margin-bottom: 0.25rem;
    }

    &.big {
        max-width: 11.5625rem;
        max-height: 11.5625rem;
    }

    &-command {
        margin-top: 0;
        margin-bottom: 0;
        max-width: 3.375rem;
        max-height: 3.375rem;
    }
    
    @media(max-width: 768px){
        &-command {
            margin-top: 0;
            margin-bottom: 0;
            max-width: 35px;
            max-height: 35px;
        }
    }
    
    &-padlock {
        stroke: $greyDarker;
    }

    &-page-down {
        // height: 104px;
        max-height: initial;
        display: block;
        margin: 0 auto 45px;
        max-width: initial;
        // width: 157px;
    }

    &-feedly-help {
        stroke: $white;
    }

    &-support {
        width: 120px;
        height: 65px;
    }

    &-calendar-big {
        width: auto;
        height: 110px;
        max-width: initial;
        max-height: initial;
    }

    &-bill {
        height: 104px;
        width: 144px;
    }

    &-home {
        fill: rgba(148, 148, 148, 1);
    }

    &-close {
        fill: $greyMedium;
    }

    &-economize {
        fill: rgba(0, 0, 0, 0.05);
    }

    &-transfer {
        fill: rgba(0, 0, 0, 0.035);
    }

    &-button {
        height: 100%;
        position: relative;
        width: 100%;
    }

    &-valid {
        fill: $green;
        min-width: 1rem;
    }

    &-error {
        fill: red;
    }

    &-delete {
        fill: $actionsColor;
    }

    &-up,
    &-down {
        stroke: $actionsColor;
    }

    &-down {
        transform: rotate(180deg);
        transform-origin: center;

        &.to-right {
            transform: rotate(90deg);
        }
    }

    &-mail-sent {
        stroke: white;
        max-width: 55px;
        max-height: 55px;
        margin-right: 10px;
    }

    &-search {
        fill: rgba($white, 0%);
        stroke: $actionsColor;
    }

    &-actions {
        height: 100%;
        position: relative;
        width: 100%;
        margin: 0 !important;

        i,
        i:before,
        i:after {
            background-color: grey;
            height: 4px;
            border-radius: 4px;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 4px;
            display: block !important;
            transform: translateY(-50%);
        }

        i {

            &:before {
                content: "";
                top: calc(50% - 7px);
            }

            &:after {
                content: "";
                top: calc(50% + 7px);
            }
        }

    }

    &-burger {
        fill: rgba($white, 0);
        display: inline-block;
        width: 18px;
        cursor: pointer;
        text-decoration: none;

        i {
            top: -5px;
            position: relative;
            display: inline-block;
            width: 18px;
            height: 1px;
            color: rgba($greyDarker, 0.75);
            text-transform: uppercase;
            text-indent: -55px;
            background: rgba($greyDarker, 0.75);
            transition: all .2s ease-out;

            &:before, &:after {
                content:'';
                width: 18px;
                height: 1px;
                background: rgba($greyDarker, 0.75);
                position: absolute;
                left:0;
                transition:all .2s ease-out;
            }

            &:before {
                top: -4px;
            }

            &:after {
                bottom: -4px;
            }

            &:hover {
                color: $greyDarker;

                i:before {
                    background: $greyDarker;
                    top: -6px;
                }

                i:after {
                    background: $greyDarker;
                    bottom: -6px;
                }
            }
        }

        &.active {
            i {
                background: $greyLight;

                &:before {
                    top: 0;
                    transform: rotateZ(45deg);
                }

                &:after {
                    bottom:0;
                    transform: rotateZ(-45deg);
                }
            }
        }

    }

    &-small-arrow {
        // reset foundation .button styles
        padding: 0;
        border: none;
        border-radius: 0;
        margin: 0;
        font-size: 1em;
        background-color: transparent;
        color: transparent;

        // styles of real custom select button
        height: 10px;
        width: 10px;
        position: absolute;
        right: 20px;
        transform-origin: center;
        top: 50%;
        transform: translateY(-50%);
        transition: transform ease 0.35s;

        &::before,
        &::after {
            height: 2px;
            background-color: $actionsColor;
            top: 50%;
            content: "";
            position: absolute;
            margin-top: -1px;
            width: 7px;
            transform-origin: center;
            transition: transform ease 0.35s;
        }

        &::before {
            right: 3px;
            transform: rotate(50deg);
        }

        &::after {
            right: 0;
            transform: rotate(-50deg);
        }
    }

}

.verified-visa, .sogenactif, .wire-transfer-hover, .wire-transfer, .card-protect, 
.contract-valid, .cb-hollow-hover, .cb-hollow, .receive-bill{
    max-width: 100%;
    background-size: 100%;
    background-image: url('../img/sprite.png');
}
 
.verified-visa { background-position: 0 0%; background-size: 100%; }
.sogenactif { background-position: 0 16.176471%; background-size: 166.081871%; }
.wire-transfer-hover { background-position: 0 25.650558%; background-size: 330.232558%; }
.wire-transfer { background-position: 0 36.05948%; background-size: 330.232558%; }
.card-protect { background-position: 0 48.076923%; background-size: 338.095238%; }
.contract-valid { background-position: 0 66.666667%; background-size: 364.102564%; }
.cb-hollow-hover { background-position: 0 80%; background-size: 405.714286%; }
.cb-hollow { background-position: 0 90%; background-size: 405.714286%; }
.receive-bill { background-position: 0 100%; background-size: 631.111111%; }