.dev {
    &-box {
        color: $white;
        position: fixed;
        top: 15px;
        right: 0;
        left: 0;
        z-index: 999999;
        margin: 0 auto;
        padding: 15px;
        max-width: 360px;
        background-color: $blueDark;

        label {
            color: $white;
        }
    }
}
