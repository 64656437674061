@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,300,400,600,700);

@import 'edenred/variables';
@import 'edenred/mixins';

// Components Foundation
@import 'foundation';
@include foundation-everything(true);

@import './edenred/reset';
@import './edenred/typo';
@import './edenred/helpers';

// modules Edenred
@import './edenred/layout';
@import './edenred/layoutConnected';
@import './edenred/cta';
@import './edenred/title';
@import './edenred/header';
@import './edenred/sidebar';
@import './edenred/forms';
@import './edenred/icons';
@import './edenred/nav';
@import './edenred/footer';
@import './edenred/pages';
@import './edenred/feedly';
@import './edenred/summary';
@import './edenred/faq';
@import './edenred/tunnel';
@import './edenred/stepper';
@import './edenred/simulation';
@import './edenred/ranges';
@import './edenred/table';
@import './edenred/dropdown';
@import './edenred/autocomplete';
@import './edenred/address';
@import './edenred/infoCard';
@import './edenred/tooltip';
@import './edenred/animations';
@import './edenred/cms';
@import './edenred/testimonial';
@import './edenred/timeline';
@import './edenred/featured';
@import './edenred/errors';
@import './edenred/loaderApp';
@import './edenred/toaster';
@import './edenred/devMod';
@import './edenred/excLayout';
@import './edenred/breadcrumb';
@import './edenred/cardDetails';
@import './edenred/cardInfos';
@import './edenred/badge';
@import './edenred/beneficiaries';
@import './edenred/accordion';
@import './edenred/navToggle';
@import './edenred/search';
@import './edenred/filter';
@import './edenred/box';
@import './edenred/pagination';
@import './edenred/siret';
@import './edenred/counter';
@import './edenred/sprite';
@import './edenred/ie';

// @import './vendors/select2';
@import './vendors/mCustomScrollbar.min';
@import './vendors/pickadate.classic';
@import './vendors/pickadate.classic.date';
@import './vendors/pickadate.classic.time';
@import './vendors/slick';
@import './vendors/slick-theme';
@import './vendors/ngDialog';
@import './vendors/noUiSlider';
