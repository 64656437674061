$feedlyIconSize: 20px;

//vars
$bg: #673ab7;
$pi: 3.14;

//config
$feedly-items: 5;
$open-distance: 85px;
$opening-angle: $pi*2;

%ball {
    background: $blueTitle;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 35px;
    left: -10px;
    color: white;
    text-align: center;
    box-shadow: 0 0.125rem 0.175rem rgba(0, 0, 0, 0.15);
    transform: translateY(0);
    transform: translate3d(0, 0, 0);
    transition: transform ease-out 200ms;
}

%tooltipCanvas {
    background-color: $blueDark;
    white-space: nowrap;
    text-align: left;
    font-size: 0.75rem;
    color: $white;
    padding: 0.25rem 0.5rem;
    // min-width: 300px;
    min-width: auto;
    margin: 0;
    position: absolute;
    right: calc(100% + 15px);
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    border-radius: $borderRadius;
    z-index: 9999;
    transition: opacity ease $animTime;
}

%tooltipArrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    border-width: 8px 0 8px 12px;
    border-color: transparent transparent transparent $blueDark;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
}

%toggleFeedlyBtnIcon {
    svg {
        opacity: 0;
    }

    .hamburger {
        display: block;
        opacity: 1;
        transition: opacity ease $animTime;
        transition-delay: $animTime*3;

        &-1 {
            transform:translateY(0) rotate(45deg);
            transform:translate3d(0, 0, 0) rotate(45deg);
        }

        &-2 {
            transform:translateY(0) scale(0.1, 1);
            transform:translate3d(0, 0, 0) scale(0.1, 1);
        }

        &-3 {
            transform:translateY(0) rotate(-45deg);
            transform:translate3d(0, 0, 0) rotate(-45deg);
        }
    }
}

$hamburger-spacing: 8px;
.hamburger {
    $width: 25px;
    $height: 3px;
    width: $width;
    height: $height;
    background: white;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -$width/2;
    margin-top: -$height/2;
    display: none;
    opacity: 0;
    transition: opacity ease $animTime*3, transform ease $animTime;

    &-1 {
        transform: translateY(-$hamburger-spacing);
        transform: translate3d(0, -$hamburger-spacing, 0);
    }

    &-2 {
        transform: translateY(0);
        transform: translate3d(0, 0, 0);
    }

    &-3 {
        transform: translateY($hamburger-spacing);
        transform: translate3d(0, $hamburger-spacing, 0);
    }
}

$feedlyCloseButtonColor: #afafaf;

.feedly {
    position: fixed;
    right: 35px;
    bottom: 35px;
    z-index: 999;
    transition: z-index ease 600ms;

    &-sidebar {
        @extend .help-sidebar;
        background-color: $greyLight;
    }

    &-header {
        @extend .help-header;
        padding: 20px;
    }

    &-content {
        @extend .help-content;
        padding: 20px;
        min-height: 300px; /* Quick fix for display lists with only one select inside before finding a better solution for scrollbar custom overflow */
    }

    &.under-footer {
        z-index: 995;
    }

    &-nav {
        $width: 50px;
        $height: 260px;

        height: $height;
        position: relative;
        width: $width;
        transition: transform ease $animTime;

        &.active {
            transform: translateX(-$feedlyWidth);
            z-index: 1000;

            .feedly-item {
                &:before, &:after {
                    opacity: 1;
                    visibility: visible;
                }
            }

            .feedly-nav-open-button {
                background-color: $feedlyCloseButtonColor;
                cursor: pointer !important;
            }
        }

        &:hover {
            .feedly-item {
                transition-timing-function:cubic-bezier(0.165, 0.840, 0.440, 1.000);

                &:before,
                &:after {
                    opacity: 1;
                    visibility: visible;
                }

                @for $i from 1 through $feedly-items{

                    &:nth-child(#{$i + 3}) {
                        transition-duration: 90ms + (100ms*$i);
                        transform: translateY(-70px*$i);
                        transform: translate3d(0, -70px*$i, 0);
                    }
                }
            }

            .feedly-nav-open-button {
                transition-timing-function: linear;
                transition-duration: 200ms;
                // transform: scale(0.8,0.8) translate3d(0,0,0);

                background-color: $feedlyCloseButtonColor;
                transform: scale(0.8, 0.8) translateY(0);
                transform: scale(0.8, 0.8) translate3d(0,0,0);
                box-shadow: 0 0.2rem 0.275rem rgba(0, 0, 0, 0.45);

                @extend %toggleFeedlyBtnIcon;
            }
        }
    }

    &-nav-open {
        display: none;

        &:checked {

            + .feedly-nav-open-button {
                transition-timing-function: linear;
                transition-duration: 200ms;

                background-color: $feedlyCloseButtonColor;
                transform: scale(0.8, 0.8) translateY(0);
                box-shadow: 0 0.2rem 0.275rem rgba(0, 0, 0, 0.45);

                @extend %toggleFeedlyBtnIcon;
            }

            ~ .feedly-item {
                transition-timing-function:cubic-bezier(0.165, 0.840, 0.440, 1.000);
                    @for $i from 1 through $feedly-items{

                    &:nth-child(#{$i + 3}) {
                        transition-duration: 90ms + (100ms*$i);
                        transform: translateY(-70px*$i);
                        transform: translate3d(0, -70px*$i, 0);
                    }
                }
            }

        }

        &-button {
            @extend %ball;
            z-index: 2;
            transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
            transition-duration: 400ms;
            transform: scale(1.1, 1.1) translateY(0);
            transform: scale(1.1, 1.1) translate3d(0,0,0);
            cursor: default !important;
            margin: 0 !important;
            // position: relative;

            svg {
                opacity: 1;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                margin: -0.5rem auto 0;
                transition: opacity ease $animTime/2;
            }

            &:hover {
                background-color: $feedlyCloseButtonColor;
                transform: scale(0.8, 0.8) translateY(0);
                transform: scale(0.8, 0.8) translate3d(0,0,0);
                box-shadow: 0 0.2rem 0.275rem rgba(0, 0, 0, 0.45);

                @extend %toggleFeedlyBtnIcon;
            }
        }
    }

    &-item {
        @extend %ball;

        &:before {
            content: attr(data-tooltip);
            @extend %tooltipCanvas;
            top: 50%;
            right: calc(100% + 15px);
            font-size: 0.75rem;
            padding: 0.25rem 0.5rem;
            line-height: 1.4;
            transition-duration: 0s;
            transform: translateY(-50%);
        }

        &:after {
            content: "";
            @extend %tooltipArrow;
            right: 59px;
            visibility: hidden;
            opacity: 0;
            transition: opacity ease $animTime;
            transition-duration: 0s;
        }

        &:hover {
            background-color: $itemActif;
        }

        &.active {
            background-color: $itemActif;

            &:before,
            &:after {
                visibility: visible;
                opacity: 1;
            }
        }

        @for $i from 1 through $feedly-items {
            &:nth-child(#{$i+2}) {
                transition-duration: 180ms;
            }
        }

        svg {
            stroke: $white;
            position: absolute;
            left: 0;
            right: 0;
            margin: -0.5rem auto 0;
            top: 50%;
        }
    }

    &-tooltip {
        @extend %tooltipCanvas;
        bottom: 11px;

        &:before {
            content: "";
            @extend %tooltipArrow;
        }
    }

    .selectric {
        background-color: $white;
        margin-bottom: 1rem;
    }

    .selectric-items {
        background-color: $white;
    }
}

.siret-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

@media(max-width: 768px){
    .feedly-nav {
        height:100%;
    }
}