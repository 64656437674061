/* ==========================================================================
   $BASE-PICKER
   ========================================================================== */
/**
 * Note: the root picker element should *NOT* be styled more than what’s here.
 */
.picker {
  font-size: 16px;
  text-align: left;
  line-height: 1.2;
  color: #000000;
  position: absolute;
  z-index: 10000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/**
 * The picker input element.
 */
.picker__input {
  cursor: default;
}
/**
 * When the picker is opened, the input element is “activated”.
 */
.picker__input.picker__input--active {
  border-color: #0089ec;
}
/**
 * The holder is the only “scrollable” top-level container element.
 */
.picker__holder {
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/*!
 * Classic picker styling for pickadate.js
 * Demo: http://amsul.github.io/pickadate.js
 */
/**
 * Note: the root picker element should *NOT* be styled more than what’s here.
 */
.picker {
  width: 100%;
}
/**
 * The holder is the base of the picker.
 */
.picker__holder {
  position: absolute;
  background: #ffffff;
  border: 1px solid #aaaaaa;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
  min-width: 176px;
  max-width: 466px;
  max-height: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  -webkit-transform: translateY(-1em) perspective(600px) rotateX(10deg);
          transform: translateY(-1em) perspective(600px) rotateX(10deg);
  transition: -webkit-transform 0.15s ease-out, opacity 0.15s ease-out, max-height 0s 0.15s, border-width 0s 0.15s;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out, max-height 0s 0.15s, border-width 0s 0.15s;
}
/**
 * The frame and wrap work together to ensure that
 * clicks within the picker don’t reach the holder.
 */
.picker__frame {
  padding: 1px;
}
.picker__wrap {
  margin: -1px;
}
/**
 * When the picker opens...
 */
.picker--opened .picker__holder {
  max-height: 25em;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
  border-top-width: 1px;
  border-bottom-width: 1px;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0);
          transform: translateY(0) perspective(600px) rotateX(0);
  transition: -webkit-transform 0.15s ease-out, opacity 0.15s ease-out, max-height 0s, border-width 0s;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out, max-height 0s, border-width 0s;
  box-shadow: 0 6px 18px 1px rgba(0, 0, 0, 0.12);
}
