/* FONTS DECLARATIONS */
$roboto: 'Roboto', sans-serif;
$lato: 'Lato', sans-serif;
$montserrat: 'Montserrat';

$bodyColor: rgb(95, 99, 105);

$white: rgb(255, 255, 255);
$blue: #7dc2e6;
$blueTitle: #162056;
$blueDark: rgb(34, 53, 59);
$blueEdenred: rgb(33, 30, 80);
$blueConnected: #1482c5;
$green: rgb(94, 192, 161);
$greenStepper:#39b54a;
$greenLemon: rgb(158, 200, 61);
$primaryColor: #72b4dc;
$grey: rgb(73, 73, 73);
$greyText: rgb(147, 149, 151);
$greyBorder: rgb(228, 228, 228);
$greyLight: #efefef;
$greyText: #808080;
$greyMedium: rgb(187, 187, 187);
$greyRegular: $greyBorder;
$greyDarker: rgb(95, 99, 105);
$greyStepper: #d6d6d6;
$orangeFlat: rgb(242, 108, 82);
$alertColor: #fb483a;
$alertColorBackground: rgba(251, 72, 58, 0.1);
$infoColor: #1482c5;
$infoBorderColor:#7dc2e6;
$infoBackgroundColor: rgba(20, 130, 197, 0.1);
$whitesmoke: whitesmoke;
$actionsColor: rgb(175, 175, 175);
$disabledColor: rgb(234, 234, 234);
$errorColor: #D44950;
$successColor: #65bc9f;
$itemActif: $primaryColor;

$edPrimary:#162056;
$edPrimaryHover: #0085ca;

$activeRowColor: rgb(248, 248, 248);
$activeRowColorConnected: rgb(248, 252, 255);
$hollowColor: rgb(168, 57, 35);

$headerHeight: 60px;
$tunnelPadding: 35px;
$tunnelPaddingConnected: 20px;
$footerClosedHeight: 80px;
$stepperSize: 220px;
$inputHeight: 40px;
$marginBottomDefault: 35px;
$marginTopDefault: 35px;
$marginBase: 1rem;
$headerConnection: 110px;

// $animEase: cubic-bezier(1.000, 0.020, 0.365, 1.240);
$animEase: cubic-bezier(.26, .6, .67, .97);
$bounce: cubic-bezier(.26, .6, .67, 2);
$borderRadius: 0.3125rem;
$feedlyWidth: 530px;
$animSidebar: $animEase;
$animTime: 0.35s;
$animTime: 0.2s;

// $shadowCircle: 0 0.188em 0.375em rgba(0, 0, 0, 0.23);
$shadowCircle: 0 0.125em 0.175em rgba(0, 0, 0, 0.15);
$shadowOverlay: 0 0 0.438em rgba(207, 207, 207, 0.5);
