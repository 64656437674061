.featured {

    &-list {
        list-style: none;
        padding: 0;
        margin: 40px 0;
    }

    .title-content {
        text-align: center;
        font-weight: 400;
        margin-top: 30px;
        display: block;
    }

}

$heightItem: 150px;

.list {

    &-item {
        padding: 10px;
        border: 1px solid $greyBorder;
        border-radius: $borderRadius;
        min-height: $heightItem !important;
        margin: 0 10px !important;

        &-title {
            font-size: 1.125rem;
            font-family: $montserrat;
            font-weight: bold;
        }

        &-content {
            font-size: 0.9375rem;
            margin: 0;
        }
    }
}
