.tunnel {

    &-wrapper {
        max-width: 970px;
        margin: 0 auto;
        background-color: white;
        z-index: 2;
        border: 1px solid #e8ecf3;

        &.connected {
            height: 100%;
            background-color: $greyLight;
            // padding: $tunnelPaddingConnected $tunnelPadding calc(#{$tunnelPaddingConnected} + #{$footerClosedHeight});
            padding: $tunnelPaddingConnected $tunnelPadding;
        }
    }

    &-stepper {
        // width: 220px;
        width: 160px;
        position: fixed;
        top: $tunnelPadding + $headerHeight;
        left: $tunnelPadding;
        z-index: 99;
        transform: translateX(0);
        transition: transform ease-in-out 0.75s;
    }

  &-content {
        display: block;
        width: 100%;

        .mCSB_scrollTools_vertical {
            right: -$tunnelPadding !important;
        }

    &__wrapper {
        display: flex;
        position: relative;
        height: 100%;
        // margin-left: $stepperSize;
        // padding-right: $stepperSize;
        // height: calc(100vh - (#{$tunnelPadding} * 2) - #{$headerHeight} - #{$footerClosedHeight});
        // max-height: calc(100vh - #{$headerHeight} - #{$tunnelPadding} - #{$footerClosedHeight});

        &.mCustomScrollbar {
            width: 100%;
        }

        > .mCustomScrollBox {
            width: 100%;
            // padding-right: $stepperSize;
        }

        > .mCSB_scrollTools {
            position: absolute;
            // right: 185px !important;
            right: -26px !important;
            top: 0;
            bottom: 0;

            .mCSB_dragger_bar {
                margin-right: 0 !important;
            }
        }

        @media(max-width: 1279px){

            .mCustomScrollBox {
                padding-right: 0;
            }
        }
        @media(max-width: 768px){
            padding-left: 0;
        }
    }
  }

}

.content {
    margin: 0 auto;
    max-width: 960px;
    overflow: auto;
    height: 80vh;

    &.expanded {
        max-width: none;
    }
    
    form {
        margin: 3.75rem 3.75rem 1.875rem;

         &.form-medium {
            /* text-align: center; */
            margin: 1rem auto;
         }
    }

    @media(max-width: 768px){
        form {
            margin: 1rem;

            .form-medium {
                height: 100vh;
            }
        }
        &.without-stepper{
            width: 100%;
            margin: 0;
            max-width: 100%;
            padding: 1rem;
        }
    }

    @media (max-width: 1280px){
        &.with-stepper {
            padding-left: 195px;
        }
    }

    &-small {
        max-width: 320px;
    }

    &-medium {
        max-width: 520px;
    }

    &-tablet {
        max-width: 760px;
        padding: 1rem 0 0 0;

        &.paiement {
            max-width: 100%;

            form {
                margin-top: 0;
            }
        }
    }

    @media (max-width: 768px){
        &-tablet {
            padding: 1rem;

            form {
                margin: 2rem 1rem;
            }
        }
    }

    &-large {
        max-width: 1200px;

        @media (max-width: 1660px){
            &.with-stepper {
                padding-left: 195px;
            }

        }
    }

    &-header {
        text-align: center;
        margin-bottom: 2.8125rem;
        margin-top: 1.875rem;

        .title {
            font-size: 1.625rem;
            font-weight: normal;
        }

        h1 {
            color: $blueTitle;
            font-size: 1.125rem;
            font-weight: bold;
        }
    }

    @media (max-width: 768px){
        &-header {
            margin: 1rem;
        }
    }

    &-inner {
        min-width: 588px;
    }

    .beneficiaire {
        @media (max-width: 1660px){
            padding-left: 195px !important;
        }
    }
    
    &.with-legals {

        form {

            &.form-medium {

                input, .submit-wrapper {
                    width: 100%;
                    min-width: 300px;

                    button {
                        width: 100%;
                    }
                }

                .input-wrapper {
                    &.names {
                        label {
                            font-weight: bold;
                        }
                    }
                }
            }

            &#newBeneficiaryForm {
                margin-top:0;
            }
        }

        h1 {
            color: $blueTitle;
        }

        @media (max-width: 768px){
            p {
                margin: 1rem;
            }

            form {
                max-width: 100%;
                
                .table-new-benef {
                    .column.large-11 {
                        width: 100%;
                        flex: none;
                    }

                    .column.large-1 {
                        .row {
                            justify-content: center;
                        }
                    }

                    p.counter {
                        margin: 0;
                        max-width: 100%;
                    }
                }

                .table-list {
                    .row {
                        flex-flow: row nowrap;
                        
                        .column.small-12 {
                            flex: none;
                            width: 28%;

                            .counter {
                                margin: 0;
                            }

                            input {
                                padding: 0;
                                font-size: 0.75rem;
                            }
                        }
                    }
                }

                .selectric-wrapper {
                    .selectric {
                        .label {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

#coordonnees {
    .content-header {
        margin-bottom: 0;
    }
    form {
        .form-medium{
            margin-top: 0;
        }
    }
    .legals-display {
        margin-top: 0;
    }
}

@media(max-width: 768px){
    .content {
        &.summary {
            .primary-btn {
                &.recapButton {
                    margin-bottom: 120px;
                    font-size: 14px;
                }
            }
        }

        .timeline-item {
            .left {
                margin-bottom: 120px;
            }
        }

        &.with-legals {
            .fake-table {
                &.table-list {
                    .column {
                        &.large-1 {
                            max-width: 1rem;
                        }
                    }
                }
            }
        }
    }
}
