.intro {
    display: flex;
    flex-flow: row wrap;
    margin-top: 14px;
    z-index: 3;

    @media(min-width: 1025px) {
        margin-top: ($headerHeight + 142px);
        &.notopmargin{
            margin-top: 0;
        }
    }

    @media(max-width: 1024px) {
        margin-top: 30px;
        &.notopmargin{
            margin-top: 0;
        }
    }

    @media(max-width: 768px) {
        margin-top: 14px;
    }

    .bandeau {
        flex-direction: column;
        width: 100%;
        height: 125px;
        @media(max-width:1024px) {
            height:170px;
        }
        @media(max-width:767px) {
            height:125px;
        }
    }
    .onlyPhone {
        display: flex;
        @media(min-width:768px) {
            display: none
        }
    }
    .onlyIpad{
        display: flex;
        @media(min-width:1024px) {
            display: none
        }
        @media(max-width:767px) {
            display:none;
        }
    }
    .ipadToDesk {
        display: none;
        @media(min-width:1024px) {
            display: flex;
        }
    }

    .intro-title {
        &.h3 {
            color: $blueTitle;
        }
    }

    @media(max-width: 1366px) {

        .icon.big {
            display: none;
        }

        .intro-title {
            font-size: 1.125rem !important;
        }

    }

    > .columns  {
        height: 82vh;
        position: relative;
        overflow: auto;

        &:first-child {
            background-color: #f2f2f2;
        }

        &:last-of-type {
            flex-direction: row;
            background-color: rgba($orangeFlat, 0.15); /* Old value 0.88 */
            position: relative;
        }
    }


    &.connected {
        margin-top: 0;

        > .columns  {
            height: calc(100vh - 60px);
        }
    }

    .basket-init {
        position: relative;

        .outer-content {
            position: absolute;
            bottom: -70px;
            left: 0;
            right: 0;
            margin: 0px auto;
        }

        .validation-invalid {
            margin: 0 13%;
        }

        .small-box {
            margin: 35px auto;
        }

        @media(max-width: 768px){
            margin-bottom: 120px;
            
            p {
                font-size:0.9375rem;
                margin: 0 2rem;
            }

            .small-box {
                margin: 1.25rem auto 1rem;
            }
        }
    }

    .inner-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 30px;
        // transform: translate3d(0, -50%, 0);
        // top: 50%;
        @include transition(transform, 0.5s);

        &.step-1 {
            display: none;
        }

        &.step-2 {
            padding-top: 120px;
            height: calc(100vh - 80px);
        }
    }

}

@media(max-width: 768px){
    .simulation {
        .content-header {
            margin-bottom: 50px;

            p {
                margin: 1rem;
            }
        }

        .range-description {
            margin-top: 60px;
        }
    
        &.content {
            form {
                margin: 0;
                height: 100vh;

                .range-row {
                    width: 80%;
                    margin-bottom:0 !important;

                    .column:first-child {

                        .range-exc {
                            margin-bottom: 34px;
                        }
                    }

                    .column:last-child {
                        .range-exc {
                            margin-bottom: 50px;
                        }
                    }

                    .range-description {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}


.legal-box {
    padding: 2rem 2.25rem;
    border: 1px solid $greyBorder;
    margin-bottom: $marginBottomDefault;

    &-title {
        position: relative;
        padding-left: 40px;

        &:before {
            content: "";
            height: 40px;
            background-image: url('../img/icon-contrat.png');
            position: absolute;
            top: 50%;
            left: -9px;
            width: 35px;
            transform: translateY(-50%);
        }
    }

    .inner-text {
        padding-left: 40px;
        font-size: 0.875rem;
    }

    .checkbox-input + .checkbox-label {
        padding-left: 40px;

        &:after {
            top: 3px;
            transform: none;
        }
    }

    &.ng-enter,
    &.ng-leave {
        transition: opacity cubic-bezier(0.250, 0.250, 0.750, 0.750) $animTime*2;
    }

    &.ng-enter {
        opacity: 0;
    }
    &.ng-enter.ng-enter-active,
    &.ng-leave {
        opacity: 1;
    }

    &.ng-leave.ng-leave-active{
        opacity: 0;
    }
}

.siret-ko {
    p {
        margin-bottom: 0;
    }
}

.legals-display {
    position: relative;
    text-align: justify;
    font-size: 0.75rem;
    padding-top: 35px;
    padding-bottom: 90px;
    margin: 3.75rem 3.75rem 1.875rem;
    max-width: 75%;

    &:before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: rgba($greyDarker, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}

@media(max-width: 768px){
    .legals-display {
        margin: 1em;
        padding-bottom: 5rem;
    }
}

form[name='contactForm'] {
    max-width: 15.0625rem;

    fieldset:first-of-type {
        margin-top: 0;
    }
}

.paiement {
    fieldset {
        article {
            background-color: #72b4dc;
            border-radius: 5px;
            color:white;
            margin: 0 auto;
            max-width: 750px;

            p {
                font-size: 1.125rem;
            }

            div {
                p {
                    padding: 10px 10px 0 0;
                }
            }
        }
    }

    form {
        overflow: hidden;
        margin-top:0;

        #transferPayment {
            .submit-wrapper {
                justify-content: flex-end;
            }
        }
    }

    @media(max-width: 768px){
        form {
            margin-top: 2rem;
        }     
    }

    .gutter-icons {
        padding-left: 100px; /* Presume that icons on gutter is 40px width and add 30px margin on it */
        position: relative;
        margin-bottom: 35px;

        &:before {
            content: "";
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            left: 50px;
            transform: translateX(-50%);
        }

        &.confirm-command {
            &:before {
                height: 34px;
                background-image: url(../img/time-clock-file-check.svg);
                width: 34px;
            }

            .input-wrapper {
                &.bic-wrapper {
                    padding-left: 20px;
                }
            }
        }

        &.facture-proforma {
            &:before {
            height: 34px;
            background-image: url(../img/accounting-invoice-mail.svg);
            width: 34px;
            }

            label {
                font-weight: 700;
            }
        }

        &.cb-paiement {

            .icons-block {
                margin-bottom: 1rem;
            }

            &:before {
                height: 34px;
                background-image: url(../img/time-clock-file-check.svg);
                width: 34px;
            }
        }
    }

}

.paiement {
    .summary:first-of-type {
        margin-bottom: 0;
    }
}

.connection {

    &-wrapper {
        display: block;
        background-color: $greyLight;
        height: calc(100vh - #{$headerConnection});

        aside {
            padding: 0 25px;
        }

        > .row {
            height: 100%;
        }

        .content-header {
            margin: 35px 0;
        }

        .login-infos {
            background-image: url(http://click.edenred.fr/Documents_Espace_Client/Portail/exc-login-cover-large.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            padding: 35px;

            @media(max-width: 1920px){
                background-image: url(http://click.edenred.fr/Documents_Espace_Client/Portail/exc-login-cover-medium.jpg);
            }

            @media(max-width: 1024px){
                background-image: url(http://click.edenred.fr/Documents_Espace_Client/Portail/exc-login-cover-small.jpg);
            }

            .row-wrap {
                margin-top: 30px;
            }

            // &:before {
            //     background-color: rgba(242, 108, 82, 0.7);
            //     content: "";
            //     position: absolute;
            //     top: 0;
            //     right: 0;
            //     bottom: 0;
            //     left: 0;
            //     z-index: 2;
            // }
        }
    }
}

@media(max-width: 768px){
    .intro {
        // margin-top: calc($headerHeight + 30px);

        .intro-title {
            &.h3 {
                font-size: 1.125rem !important;
            }
        }
    }
}