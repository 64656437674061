$searchWidth: 320px;
$searchheight: 40px;
$searchMinWidth: 40px;

.search {
    height: $searchheight;
    position: relative;
    width: 100%;
    min-width: $searchMinWidth;
    max-width: $searchMinWidth;
    overflow: hidden;
    transition: all ease $animTime;

    &.fixed {
        width: $searchWidth;

        &.active {
            max-width: $searchWidth;
        }
    }

    &:before {
        background-color: $greyBorder;
        height: 1px;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        transform: translateX(100%);
        transition: transform ease $animTime*2;
    }

    &-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        max-width: 40px;
    }

    input {
        background-color: transparent !important;
        height: 100%;
        font-size: 0.875rem;
        border: none;
        box-shadow: none;
        position: absolute;
        top: 0;
        left: 0;
        padding: 0 20px;
        width: 100%;
        box-shadow: none !important;

        &:focus {
            border: none;
        }
    }

    .icon-search,
    &-close {
        height: 1rem;
        width: 1rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: transform ease $animTime;
    }

    .icon-search {
        left: 0;
        margin: 0 auto;
        max-width: 1rem;
        max-height: 1rem;
        transition-delay: $animTime;
        cursor: pointer;
    }

    &-close {
        // position: absolute;
        // right: 0;
        // top: 50%;
        transition-delay: $animTime;
        transform: translateY(150%);

        .svg {
            height: 100%;
            // max-height: 0.875rem;
            // max-width: 0.875rem;
            width: 100%;
        }
    }

    .icon {
        transform: translateY(-50%);

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &.active {
        max-width: calc(100% - 70px);

        .icon-search {
            right: auto;
        }

        .search-content {
            max-width: 100%;
        }

        &:before {
            transform: translateX(0);
        }

        .search-close {
            transform: translateY(-50%);
        }

        // .icon-search {
        //     transform: translateX(calc(-#{$searchWidth} + 15px)) translateY(-50%);
        // }

    }

    &.inverse {

        .icon-search,
        .search-close {
            left: 0;
        }

    }
}
