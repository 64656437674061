.fake-table {

    .column {
        padding: 0 2px;

        &:first-of-type {
            padding-left: 0;
        }

        &:last-of-type {
            padding-right: 0;
        }

        .button {
            margin: auto;
        }
    }
    /* Reset some components rules for fake-table only */
    input {
        margin: 0;
        font-size: 0.925rem;
    }

    p {
        text-overflow: ellipsis;
        font-size: 0.875rem;
        white-space: pre;
        max-width: calc(100% - 10px);
        overflow: hidden;
    }


    &.disabled .input-wrapper:not(.has-error) input {
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
        border: none;
        color: #323232;
    }

    &.disabled .input-wrapper.has-error p,
    &.disabled .input-wrapper.has-error input {
        box-shadow: none;
        border: 1px solid $errorColor;
    }

    .actions-wrapper {
        height: $inputHeight;
        flex: 0 1 65px;
        align-self: flex-end;
    }

    .actions-wrapper-top {
        height: 3.375rem;
        flex: 0 1 65px;
        align-self: flex-start;
    }
}

.table {

    &-list {
        padding: 5px 0;
        border-bottom: 1px solid $greyBorder;
        transition: opacity ease 0.35s, background-color ease 0.35s, font-weight ease 0.35s;

        &:not(.disabled) {
            background-color: $activeRowColor;
        }

        &.disabled {

            &:hover {
                opacity: 1;
                background-color: $activeRowColor;

                input {
                    font-weight: 500;
                }
            }
        }
    }

    &-new-benef {
        margin-bottom: 45px;

        + .table-list {
            border-top: 1px solid $greyBorder;
        }

        .add-benef {
            display: table;
            max-width: 100%;
            margin-top: 31px !important;

            svg {
                height: 100%;
                fill: white;
                display: table-cell;
                vertical-align: middle;
                margin: 0 auto;
                width: 100%;
                &.icon{
                    max-height: 2.3rem;
                }
            }
        }
        
        .icon-up,
        .icon-down {
            fill: rgba($bodyColor, 0.65);
            stroke: transparent;
            position: absolute;
            // margin-bottom: -10px;
        }

        .icon-up {
            top: 7px;
        }

        .icon-down {
            top: 14px;
        }

        .active {
            fill: rgba($bodyColor, 0.90);
        }
        .inactive {
            fill: rgba($bodyColor, 0.40);
        }
        .row {
            .column {
                label {
                    font-weight: bold;

                    .mandatory {
                        color: #d52b1e;
                    }
                }
            }
        }
    }
}

.on-edit {
    .disabled,
    .table-new-benef {
        position: relative;
        z-index: 1;
        opacity: 0.5;

        &:before {
            content: "";
            background-color: rgba($white, 0.5);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            opacity: 0.5;
        }

        &:hover {
            opacity: 0.5;
            background-color: rgba($white, 0.5);

            input {
                font-weight: inherit;
            }
        }

    }
}
