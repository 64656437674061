.cta {
  @include button;

  &-rounded {
      height: 42px;
      background-color: $green;
      color: $white;
      margin: 0;
      border-radius: 50%;
      width: 42px;
      outline: none;
      box-shadow: $shadowCircle;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      transform: scale(1);
      transition: background-color ease $animTime, transform cubic-bezier(.25,.11,.56,2) $animTime;
      z-index: 999;

      &:hover,
      &.active {
          background-color: lighten($green, 5%);
      }
  }

    &-link {
        color: #72A0D1;
        font-size: 0.75rem;
        transition: all ease $animTime;
        position: relative;
        margin-top: 1rem;
        display: inline-block;

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: -2px;
            left: 0;
            background-color: #72A0D1;
            visibility: hidden;
            transform: scaleX(0);
            transition: all 0.3s ease-in-out 0s;
        }

        &:hover {
            color: darken(#72A0D1, 20%);

            &:before {
                visibility: visible;
                transform: scaleX(1);
            }
        }
    }
}

submit-button {
    display: block;
    overflow: hidden;
    padding: 0 !important; /* remove some surcharge from helpers class */
    position: relative;
    margin: 0 auto;

    .button {
        margin-bottom: 0;
        max-width: 100%;
        width: 100%;
    }
}

@media (max-width: 768px) {
    submit-button {
        margin-bottom: 120px;
    }
}

.button {
    height: $inputHeight;
    font-size: 1rem;
    font-weight: bold;
    position: relative;
    transition: all ease $animTime;
    overflow: hidden;
    color: white;
    border-radius: 3.125rem;
    padding: 0 2.1875rem 0 2.1875rem;

    .inner-text {
        display: inline-block;
        // position: absolute;
        // top: 50%;
        // transform: scale(1);
        transform: translateY(0);
        transition: transform ease $animTime;
        transition-delay: $animTime;
    }

    .spinner-wrapper {
        left: 0;
        right: 0;
        top: 50%;
        display: flex;
        position: absolute;
        justify-content: center;
        z-index: -999;
        // transform: translateY(150px);
    }

    &-loading {
        animation: bulletLoading;

        .spinner-wrapper:not(.start-anim) {
            // transform: translateY(-50%);
            z-index: 0;
        }

        .inner-text {
            // transform: translateY(calc(-50% - 50px));
            transform: translateY(50px);
            transition-delay: 0s;
        }
    }

    &.expanded {
        height: 100%;
    }

    &-block {
        display: block;
        margin: 0 auto;
        width: auto !important;
    }

    &-inline {
        display: inline-block;
        width: auto !important;
        margin: 0 auto;
    }

    &-corporate {
        background-color: #9ec83d;
        display: inline-block;
    }

    &-success {
        background-color: $successColor;
    }

    &.valid {
        background-color: $greenLemon;

        &:hover {
            background-color: #8db72c;
        }

        &:not(.button-loading).disabled,
        &:not(.button-loading)[disabled] {
            background-color: #ededed;
            color: #acacac !important;
            border-color: #b5b5b5;

            &:hover {
                cursor: not-allowed;
            }
        }
    }

    &-group {

        .button {
            min-width: auto !important;
        }

        submit-button {
            width: 100%;
            margin: 0;

            &:not(:first-child) {
                margin: 0 0 0 10px;
            }
        }
    }
    
    &-alert {
        background-color: $orangeFlat;
        
        &:hover {
            background-color: lighten($orangeFlat, 6%);
        }
    }

    &.secondary {
        border-radius: $borderRadius;
        border: 0.063em solid $greyBorder;
        background-color: $whitesmoke;
        transition: all ease $animTime;

        &.hollow {
            border: 0.063em solid $greyText;
            color: $greyText;
            
            background-color: transparent;

            .spinner-dot {
                background-color: $greyText;
            }

            &:hover {
                color: #5f6369 !important;
                border-color: #acacac;
            }

            &:not(.button-loading).disabled,
            &:not(.button-loading)[disabled]{
                background-color: #ededed;
                color: #acacac !important;
                border-color: #b5b5b5;

                &:hover {
                    cursor: not-allowed;
                }
            }
        }

        &:hover,
        &:focus {
            background-color: rgba($whitesmoke, 0.5);
            border-color: darken($greyText, 20%) !important;
            color: darken($greyText, 20%) !important;
        }

    }

    &.ternary {
        color: $white;
        border: 0.063em solid $white;
        background-color: transparent;
        transition: background-color ease $animTime;

        &:hover {
            background-color: rgba(245, 245, 245, 0.3);
        }
    }

    &-action {
        background: none;
        position: relative;
        padding: 9px 12px;
        border-color: $actionsColor;
        border-width: 1px;
        border-radius: $borderRadius;
        overflow: hidden;
        transition: background-color ease $animTime;

        &:before {
            content: '';
            background-color: rgba($greyDarker, 0.05);
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            transition: transform ease $animTime;
        }

        &.up:before {
            transform: translateY(-100%);
        }

        &.down:before {
            transform: translateY(100%);
        }

        &:hover {
            background: none;

            &:before {
                transform: translateY(0);
            }
        }

    }

    &-icon {
        background: none;

        &:hover {
            background: none;
        }

        .icon {
            stroke: $actionsColor;
        }

    }

    &.hollow:not(.secondary) {
        border-color: $hollowColor;
        color: $hollowColor;
        transition: color ease 0.35s, border-color ease 0.35s;

        &:before {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 0 4px 8px;
            border-color: transparent transparent transparent $hollowColor;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: all ease 0.35s;
        }

        &.right-arrow:before {
            right: 1em;
        }

        &.left-arrow:before {
            left: 1em;
            transform: translateY(-50%) rotate(180deg);
            transform-origin: center;
        }

        &:hover {
            border-color: inherit;
            color: inherit;

            &:before {
                border-color: transparent transparent transparent $blueDark;
            }
        }

    }
    
    &.blueEXC-bg {

        .icon {
            fill: $white;
        }

    }

    &.error {
        padding: 0.75rem 1.1875rem 0 1.1875rem;
    }
    @media(max-width: 768px){
        .button.error {
            padding: 0.75rem 1.1875rem 0 1.1875rem;
        }
    }

}

.primary-btn {
    background-color: $edPrimary;
    border: 1px solid $edPrimary;

    &:hover {
        background-color: $edPrimaryHover;
        border: 1px solid $edPrimaryHover;
    }

    &:focus {
        background-color: $edPrimary;
        border: 1px solid $edPrimary;
    }

}

.actions {

    &-on-edit {
        height: 100%;
        display: flex;

        a {
            flex-grow: 1;
            display: flex;

            svg {
                margin: auto;
            }
        }
    }

    &:before,
    &:after {
        background-color: rgba(black, 0.1);
        height: 48px;
        content: "";
        width: 48px;
        transform: scale(0);
        position: absolute;
        top: 50%;
        margin: -24px auto 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        transition: transform ease $animTime, border-radius ease $animTime;
    }

    &:before {
        z-index: 0;
    }

    &[disabled] {
        cursor: not-allowed !important;

        &:before,
        &:after {
            content: none !important;
        }

        i,
        i:before,
        i:after {
            background-color: rgba($blueDark, 0.4);
        }
    }

    &:not(:active):hover:before {
        background-color: rgba(black, 0.05);
        transform: scale(1);
        border-radius: 0;
    }

    &.active {

        &:before {
            opacity: 0;
        }

        &:after {
            transform: scale(1);
            border-radius: 0;
        }
    }

    &:active {

        &:before {
            opacity: 0;
        }

        &:after {
            transform: scale(1);
        }

    }

}

.submit-wrapper {

    > .column:first-child {
        padding-left: 0 !important;
    }

    > .column:last-child {
        padding-right: 0 !important;
    }

}