.app-wrapper {
    display: flex;
    justify-content: center;
    padding-top: $headerHeight;
    flex-direction: column;

    &.expanded {
        margin-top: 0;
        padding: 0;
    }

    &.connected {
        padding-top: 0;

        .tunnel-wrapper {
            &.connected {
                #mCSB_1_container {
                    overflow: auto;
                }
            }
        }
    }

}

.ui-view-wrapper {

    &.ng-enter,
    &.ng-leave {
        transition: 0.7s linear all;
    }

    &.ng-leave {
        z-index: 2;

        /* Anim on leave Introduction page */
        > .intro {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin-top: 0;
            z-index: 0;
            // margin-top: 0;
            overflow: hidden;

            .columns:first-child {
                // background-color: $white;
                max-width: 100vw;
                transition: flex-basis ease 0.45s;

                .inner-content {
                    opacity: 1;
                    transition: opacity ease 0.45s 0.35s;
                }
            }

            .header-intro {
                transform: translateY(0);
                transition: transform ease $animTime*2;
            }
        }

        &.ng-leave-active {
            > .intro {

                .header-intro {
                    transform: translateY(-100%);
                }

                .columns:first-child {
                    flex-basis: 100vw;

                    .inner-content {
                        opacity: 0;
                    }
                }
            }

            > .tunnel-wrapper {
                
                .tunnel-stepper {
                    transform: translateX(-200%);
                    transition-duration: 0s !important;
                }

            }
        }
    }

    &.ng-enter {
        z-index: -1;
        transition: all ease 0.7s 0.7s;
        // transition: all ease 1.5s;

        .tunnel-wrapper {
            z-index: -1;
            opacity: 0;
            transition: opacity ease 0.4s;

            .tunnel-stepper {
                transform: translateX(-200%);
                transition: transform ease 0s;
            }

            .tunnel-content {
                opacity: 0;
                transition: opacity ease 1.4s;
            }

            .feedly-nav {
                opacity: 0;
                transform: translateX(150px);
                transition: opacity ease 0.25s 1s, transform ease 1s 1s;
            }
        }

        &.ng-enter-active > .tunnel-wrapper{
            opacity: 1;

            .tunnel-stepper {
                transform: translateX(-200%);
                // transition: transform ease 5s;
                // transition-delay: 5s;
            }

            .tunnel-content {
                opacity: 1;
            }

            .feedly-nav {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

}

stepper {
    display: block;

    @media(max-width: 768px) {
        display: none;
    }
}

.svg-declarations {
    height: 0;
    width: 0;
}

.flex-column {
    flex-direction: column;
}

.square {
    height: 200px;
    width: 200px;
    border: 3px solid rgba($blueDark, 0);
    transition: border-color ease 0.35s;

    &:hover {
        border-color: rgba($blueDark, 0.5);
    }
}

.row .row {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.medium {
    margin: 0 auto;
    max-width: 680px;
}

.content {

    &-small {
        margin: 0 auto;
        max-width: 320px;
    }

    &-medium {
        margin: 0 auto;
        max-width: 400px;
    }

    &-intermediate {
        margin: 0 auto;
        max-width: 475px;
    }

}

.layer-overflow {
    @extend .footer-overflow;
    background-color: rgba($blueConnected, 0.8) !important;
}

@media (min-width: 1025px) {
    .app-wrapper {
        height: 80%;

        &.connected {
            height: fit-content;
        }
    }
}