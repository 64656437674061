// [ui-view].tunnel-content {
//
//     &.ng-enter-prepare {
//         opacity: 0;
//     }
//
//     // &.ng-enter,
//     // &.ng-leave {
//     //     transition-duration: .5s;
//     //     transition-property: transform, opacity;
//     //     transition-timing-function: cubic-bezier(.17, .67, .84, 1.18);
//     // }
//     //
//     // &.ng-enter {
//     //     transition-delay: 0.5s;
//     // }
//     //
//     // &.ng-enter-active {
//     //     /* end with opacity 1 (fade in) */
//     //     // opacity: 1;
//     // }
// }


// .tunnel-content {
//
//     &.ng-enter,
//     &.ng-leave {
//         position: absolute;
//         left: 0;
//         right: 0;
//         margin: 0 auto;
//
//         :root .tunnel-content__wrapper {
//             height: calc(100vh - 130px);
//             overflow: hidden;
//         }
//     }
//
//     &.ng-enter {
//         transform: translate3d(0, 100vh, 0);
//     }
//
//     &.ng-enter-active,
//     &.ng-leave {
//         transform: translate3d(0, 0, 0);
//     }
//
//     &.ng-leave-active {
//         transform: translate3d(0, -100vh, 0);
//     }
//
// }

$animation-speed: 350ms;
$dot-size: 6px;

@mixin animationSpinner($delay: 50ms) {
	animation: loader $animation-speed ease infinite $delay;
}

@mixin animSlideInDotSpinner($delay: 50ms) {
	animation: loaderDotSlideUpDown $animation-speed ease 1 $delay;
}

.spinner {

	&-dot {
		width: $dot-size;
		height: $dot-size;
		background-color: $white;
		border-radius: 50%;
		display: inline-block;
		margin: 0 ($dot-size / 2);
	}

    &-wrapper {

		// &.ng-hide-add,
		&.ng-hide-remove {
			transition: 250ms linear all;
		}

		&.ng-hide-add {
			transition: 500ms linear all;
		}

		&.ng-hide-animate {

		    .spinner-dot {
				transform: translateY(-50px);
				@include animSlideInDotSpinner($animation-speed * 0.3);

				&:nth-child(2) {
					@include animSlideInDotSpinner($animation-speed * 0.2);
				}
				&:nth-child(3) {
					@include animSlideInDotSpinner();
				}
			}
		}

		&.ng-hide-add {

		    .spinner-dot {
				animation-direction: reverse !important;
			}
		}

		// &.ng-hide-add.ng-hide-animate {
		//
		//     .spinner-dot {
		// 		@include animSlideInDotSpinner();
		//
		// 		&:nth-child(2) {
		// 			@include animSlideInDotSpinner($animation-speed * 0.2);
		// 		}
		// 		&:nth-child(3) {
		// 			@include animSlideInDotSpinner($animation-speed * 0.3);
		// 		}
		// 	}
		// }

		&:not(.ng-hide-animate){

		    .spinner-dot {
				@include animationSpinner();

				&:nth-child(2) {
					@include animationSpinner($animation-speed * 0.2);
				}
				&:nth-child(3) {
					@include animationSpinner($animation-speed * 0.3);
				}
			}
		}
	}
}

@keyframes loader {
	0% {
		transform: translateY(0);
	}
	25% {
		transform: translateY(-3px);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes loaderDotSlideUpDown {
	0% {
		transform: translateY(50px);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes moveOnRightLight {
	0% {
		transform: translateX(0px);
	}
	100% {
		transform: translateX(8px);
	}
}
