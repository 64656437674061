.ir {
    text-indent: -9999px;
}

.fake-checkbox {
    left: -9999px;
    top: -9999px;
    position: absolute;
    height: 1px;
    width: 1px;
}

.title-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.mAuto {
    margin: 0 auto;
}

/* Colors */

.whiteC {
    color: $white !important;
}

.greyLightC {
    color: $greyLight;

    &-bg {
        background-color: $greyLight;
    }
}

.greyMediumC {
    color: $greyMedium;

    &-bg {
        background-color: $greyMedium;
    }
}

.greyDarkC {
    color: $greyDarker;

    &-bg {
        background-color: $greyDarker;
    }
}

.blueDarkC {
    color: $blueDark;

    &-bg {
        background-color: $blueDark;
    }
}

.blueStdC {
    color: $blueEdenred;

    &-bg {
        background-color: $blueEdenred;
    }
}

.blueEXC {
    color: $blueConnected;

    &-bg {
        background-color: $blueConnected;
        padding: 0 !important;

        &.button:focus {
            background-color: $blueConnected;
        }

        &.button:hover {
            background-color: lighten($blueConnected, 10%);
        }
    }
}

.orangeC {
    color: $orangeFlat;

    &-bg {
        background-color: $orangeFlat;

        &:hover {
            background-color: rgba($orangeFlat, .8);
        }
    }
}

.greenC {
    color: $green;

    &-bg {
        background-color: $green;
    }
}

.fill-white .icon {
    fill: white !important;
}

.bolder {
    font-weight: 700 !important;
}

.borderR {
    border-right: 0.063em solid $greyBorder;
}

.center {
    text-align: center;
}

.alignR {
    text-align: right;
}

.alignL {
    text-align: left !important;
}

.vCenter {
    margin: auto;
}

.vAlign {
    align-items: center;
}

.space-around {
    justify-content: space-around;
}

.centerBck {
    display: block;
    margin: auto;
}

.btF {
    position: fixed;
    bottom: 0;
}

.translate-X {
    transform: translate3d(-100%, -50%, 0) !important;
}

.translateX {
    transform: translate3d(100%, -50%, 0) !important;
}

.translate-Y {
    transform: translate3d(0, -100%, 0) !important;
}

.translateY {
    transform: translate3d(0, 100%, 0) !important;
}

.translate0 {
    transform: translate3d(0, 0, 0) !important;
}

.hidden {
    display: none;
}

.row-wrap {
    flex-wrap: wrap;
}

.vHidden {
    visibility: hidden !important;
    opacity: 0 !important;
    transition: opacity ease 0.35s;
}

.no-border {
    border: none !important;
}

.inline {
    display: inline;
}

.unit {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1em;
}

.pad-box {
    padding: 15px 30px;
}

.pad0 {
    padding: 0 !important;
}

.marg0 {
    margin: 0 !important;
}

.margB {
    margin-bottom: $marginBottomDefault;
}

.margT {
    margin-top: $marginTopDefault;
}

.margT0 {
    margin-top: 0;
}

.margT1 {
    margin-top: $marginBase;
}

.small-links {
    color: #1482c5;
    display: block;
}

.underline {
    text-decoration: underline;
}

.smaller {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.25rem;
}

.flex-me {
    display: flex;
    align-items: center;

}

.flex-me-no-align {
    display: flex;
}

.vertical-flow {
    flex-direction: column;
}

.table-me {
    display: table;
}

.column-2 {
    column: 2;
    column-gap: 40px;
    -moz-column-count: 2;
    -moz-column-gap: 40px;
    -webkit-column-count: 2;
    -webkit-column-gap: 40px;
    // .item {
    //
    //     &-title {
    //         width: 100%;
    //         display: block;
    //     }
    //
    //     &-link {
    //         display: inline-block;
    //         width: 100%;
    //     }
    //
    // }
}

.scrollY {
    overflow-y: scroll;
}

.maxW160 {
    max-width: 160px;
}

.w25 {
    width: 25%;
}

.w30 {
    width: 30%;
}

.w50 {
    width: 50%;
}

.mw70 {
    max-width: 70%;
}

.block {
    margin: 1em auto;
    display: block;
}

.padL0 {
    padding-left: 0;
}

.padR0 {
    padding-right: 0;
}

.recap {
    border: 1px solid $greyBorder;
    margin: 0 auto;
    padding: 10px;

    p {
        font-size: 1rem;
    }
}

.more-separator {
    color: rgba($greyDarker, 0.34);
    font-size: 0.875rem;
    font-style: italic;
    text-align: center;
    position: relative;
    margin: 20px -30px;
    overflow: hidden;
    z-index: 1;

    &:before,
    &:after {
        content: "";
        width: 50%;
        height: 1px;
        background-color: $greyBorder;
        position: absolute;
        top: 50%;
        margin-top: -1px;
        z-index: 0;
    }

    &:before {
        transform: translateX(calc(-100% - 20px));
    }

    &:after {
        margin-left: 20px;
    }
}

.no-overflow {
    max-height: calc(100vh - #{$headerHeight});
    overflow: hidden;
}

.margin-overflow {
    margin: 0 -3.4375rem;
    width: calc(100% + (2 * 3.4375rem)) !important;

    @media (max-width: 1240px){
        margin: 0 auto;
        width: 100% !important;
    }
}

// .align-vertical {
//     flex-direction: column !important;
//     align-items: center;
// }

// .lt-ie10 {

.align-vertical {
    display: table;

    > * {
        display: table-cell;
        vertical-align: middle;
    }

    > .columns,
    > [class*="column"] + [class*="column"]:last-child {
        float: none;
    }
}
// }

.light {
    font-weight: 300;
}

.lighter {
    font-size: 1.1rem;
    font-weight: 500;
}

.lightest {
    font-size: 0.7rem;
    font-weight: 300;
}

.img-center {
    display: block;
    margin: 20px auto 10px auto;
}

.annotation {
    font-weight: 300;
    font-size: 0.9rem;
}

.need-help {
    margin-top: 15px;
    margin-bottom: 50px;
}

.caption {

    &-title {
        font-size: 1.125rem;
        margin-bottom: 0.125rem;
    }
    &-infos {
        margin-top: 0;
        font-size: 0.875rem;
    }
}

@media(max-width: 768px){
    .paiement {
        .gutter-icons {
            &.confirm-command, &.facture-proforma, &.cb-paiement {
                padding-left:0;

                &::before {
                    display:none;
                }
            }

            .row {
                .input-wrapper {
                    min-width: 100%;
                }
            }

            &.confirm-command {
                .row {
                    .input-wrapper {
                        &.bic-wrapper {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}