$benefPadding: 30px;

.beneficiaries {

    header {
        margin: 25px 0;
    }

    &-table {
        padding-bottom: 0 !important;

        .header-actions-buttons {
            width: 100%;

            .light-title {
                margin: 0;
            }
            
            .actions-wrapper {
                text-align: right;
                float: right;
                width: 100%;
                min-width: 100px;
                width: auto;
                flex-grow: 1;

                .actions-wrapper-item {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                    margin-bottom: 0;
                    min-width: 40px;

                    .icon-plus {
                        fill: white;
                    }
                }
            }
        }
    }

    .table-list,
    .table-new-benef {
        margin: 0 #{-$benefPadding};
        padding: 5px 15px;
    }

    .table-new-benef {
        width: calc(100% + #{$benefPadding * 2});

        .column:not(:last-child) {
            padding-left: #{$benefPadding / 2};
        }
    }

}

.centeredToggleFeedlylink {
    text-align: center;
    margin-bottom: 20px;
    a {
        color: #162056;
        font-size: 0.95rem;
        font-weight: 700;
    }
}
