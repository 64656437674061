.toaster {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: -1;

    &-message {
        background-color: $blueDark;
        color: $white;
        border-radius: $borderRadius;
        padding: 10px 25px;
        position: fixed;
        top: 20px;
        right: 0;
        transform: translateX(100%);
        transition: transform $animEase $animTime*1.5;

        strong {
            font-weight: 500;
            font-size: 0.875rem;
            display: block;
        }

        small {
            font-weight: lighter;
            font-size: 0.875rem;
        }

        .float-right {
            margin-top: 6px;
            margin-left: 10px;
        }
    }

    &-background {
        height: 100vh;
        background-color: rgba($white, 0.6);
        opacity: 0;
        visibility: hidden;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        z-index: -1;
        transition: opacity ease $animTime;
    }

    &.active {
        z-index: 999998;

        .toaster {
            &-message {
                transform: translateX(0);
            }

            &-background {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
