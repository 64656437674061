.h1 {
    font-size: 3rem;
    color: $blueTitle;
}

.h2 {
    font-size: 2.25rem;
}

.h3 {
    font-size: 1.125rem;
}

.h4 {
    font-size: 1.25rem;
}

.h5 {
    font-size: 1rem;
}

.h6 {
    font-size: 0.875rem;
}

.light-title {
    font-weight: lighter;
}

.big-title {
    font-weight: 400;
    font-size: 2.375rem;
}

.content {
    h1 {
        font-weight: normal;
    }
}

.sub-title {
    font-size: 1.125rem;
    font-weight: 300;
    margin: 0;
}

@media(max-width: 768px){
    .h3 {
        font-size: 1rem;
    }
}