.card-infos {
    background-color: white;
    height: 16.625rem;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.188rem;
    border-radius: 0 0 0.313rem 0.313rem;
    box-shadow: 1px -6px 14px rgba(0, 0, 0, 0.32);
    position: relative;
    width: 17.825rem;
    margin: 50px 20px 20px 0;
    padding: 10px 25px 25px;

    &:before {
        content: "";
        position: absolute;
        top: -50px;
        right: 50px;
        width: calc(100% - 50px);
        background-color: white;
        height: 50px;
        border-radius: 0.313rem 0 0 0.313rem;
        border-bottom-left-radius: 0;
        // box-shadow: -5px -4px 19px -8px rgba(0, 0, 0, 0.32);
        box-shadow: -5px -3px 12px -6px rgba(0, 0, 0, 0.32);
    }

    .card-arrow {
        position: absolute;
        height: 90px;
        width: 49px;
        overflow: hidden;
        transform: rotate(-45deg);
        right: 22px;
        top: -56px;
        box-shadow: 19px 3px 8px -17px rgba(0, 0, 0, 0.32);

        &:before {
            content: "";
            position: absolute;
            width: 100px;
            height: 100px;
            left: 35px;
            background: $blueConnected;
            transform: rotate(45deg);
            border-radius: 10px;
        }

        &:after {
            content: "";
            height: 50px;
            position: absolute;
            background-color: $white;
            right: 0;
            top: 50%;
            width: 50px;
            margin-top: -50%;
            z-index: -1;
        }

    }

    .icon {
        stroke: $blueConnected;
        margin: 0 auto 30px;
        display: block;
        max-width: 3.313rem;
        max-height: 3.313rem;

        &-balloons {
            fill: $blueConnected;
            stroke: transparent;
        }
    }

    .content {
        font-weight: 300;
        strong {
            display: block;
        }
    }
}