.counter {
    position: relative;
    white-space: normal !important;
    margin: 0;

    button {
        background-color: rgba(0, 0, 0, 0);
        height: 50%;
        position: absolute;
        color: $placeholderColor;
        right: 10px;
        width: 30px;
        overflow: hidden;
        transition: background-color ease $animTime;

        svg {
            fill: #E3E2E2;
            position: absolute;
            right: 7px;
            max-width: 0.875rem;
            transition: fill ease $animTime;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);

            svg {
                fill: $bodyColor;
                fill-opacity: 1;
            }
        }

        &:active {
            background-color: rgba(0, 0, 0, 0.2);

            svg {
                fill: $white;
                fill-opacity: 1;
            }
        }
    }

    &-increment {
        top: 0;

        svg {
            top: 5px;
        }
    }

    &-decrement {
        top: 50%;

        svg {
            top: 5px;
        }
    }

}

.fake-table.disabled .counter button {
    display: none;
}


.material-counter {
    height: 2.375rem;
    margin: 0 10px;
    width: 80px;

    input {
        height: 100% !important;
        color: #f26c52;
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center;
        padding: 10px 15px 0;
        border: none;
        box-shadow: none;
        margin: 0;
        border-bottom: 1px solid $greyBorder;
    }
    
    .counter {
        height: 100%;

        button {
            width: 14px;

            svg {
                fill: transparent;
                stroke: $bodyColor;
                top: 0;
                right: 1px;
                max-width: 0.75rem;
                transition: stroke ease $animTime;
            }

            &:active {

                svg {
                    stroke: $white;
                }
            }

        }

        &-increment {

            svg {
                top: 5px !important;
            }

        }


    }
}