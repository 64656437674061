body.pg-loading {
  overflow: hidden;
}

$background: $white;
$spinnerColor: $blueEdenred;
.pg-loading-screen {
    background-color: $background;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000000;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    &.ng-hide-add {
      transition: 300ms linear opacity;
    }

    &.ng-hide-add-active  {
        opacity: 0;
    }

}

.pg-loading-screen.pg-loaded {
  opacity: 0;
  animation: pgAnimLoaded 0.5s cubic-bezier(0.7, 0, 0.3, 1) both;
}

.pg-loading-screen.pg-loading .pg-loading-logo-header, .pg-loading-screen.pg-loading .pg-loading-html {
  opacity: 1;
}

.pg-loading-screen.pg-loading .pg-loading-logo-header, .pg-loading-screen.pg-loading .pg-loading-html:not(.pg-loaded) {
  animation: pgAnimLoading 1s cubic-bezier(0.7, 0, 0.3, 1) both;
}

.pg-loading-screen.pg-loading .pg-loading-html:not(.pg-loaded) {
  animation-delay: 0.3s;
}

// .pg-loading-logo:first-child {
//     border-right: 1px solid $blueEdenred;
//     padding-right: 22px;
//     margin-right: 12px;
//     margin-left: -34px;
// }

// .pg-loading-logo:last-child {
//     margin-right: 22px;
// }

.pg-separator {
  background-color: $blueEdenred;
  display: inline-block;
  height: 47px;
  width: 1px;
  margin: 0 20px;
  vertical-align: middle;
}

.pg-loading-screen .pg-loading-inner {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: static;
}

.pg-loading-screen .pg-loading-center-outer {
  width: 100%;
  padding: 0;
  display: table !important;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
}

.pg-loading-screen .pg-loading-center-middle {
  padding: 0;
  vertical-align: middle;
  display: table-cell  !important;
  margin: 0;
  text-align: center;
}

.pg-loading-screen .pg-loading-logo-header, .pg-loading-screen .pg-loading-html {
  width: 100%;
  opacity: 0;
}

// .pg-loading-screen .pg-loading-logo-header {
//   text-align: center;
//   width: 360px;
//   margin: 0 auto;
// }

// .pg-loading-screen .pg-loading-logo-header img {
//   display: inline !important;
//   vertical-align: middle;
// }

.pg-loading-screen .pg-loading-html {
  margin-top: 30px;
}

.pg-loading-screen .pg-loading-html.pg-loaded {
  transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1);
}

.pg-loading-screen .pg-loading-html.pg-loaded.pg-removing {
  opacity: 0;
}

.pg-loading-screen .pg-loading-html.pg-loaded.pg-loading {
  opacity: 1;
}

@keyframes pgAnimLoading {
  from {
    opacity: 0;
  }
}
@keyframes pgAnimLoaded {
  from {
    opacity: 1;
  }
}

.sk-spinner-wave.sk-spinner {
    margin: 0 auto;
    width: 100px;
    height: 60px;
    text-align: center;
    font-size: 10px;
    overflow: hidden;
}

.sk-spinner-wave.sk-spinner {
  margin: 0 auto;
  width: 50px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}

.sk-spinner-wave div {
  background-color: $spinnerColor;
  height: 100%;
  width: 6px;
  margin: 0 1px;
  display: inline-block;
  animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
}

.sk-spinner-wave .sk-rect2 {
  animation-delay: -1.1s;
}
.sk-spinner-wave .sk-rect3 {
  animation-delay: -1s;
}
.sk-spinner-wave .sk-rect4 {
  animation-delay: -0.9s;
}
.sk-spinner-wave .sk-rect5 {
  animation-delay: -0.8s;
}

.loader-gif {
    height: 120px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    max-width: 130px;
}

.intro .loader-gif {
    display: none;
}

@keyframes sk-waveStretchDelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1);
    }
}
