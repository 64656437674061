.filter {
    
    &-list {
        list-style-type: none;
        margin: 0;
    }

    &-label {
        margin-right: 3px;
    }

    &-item {
        background-color: #ededed;
        position: relative;
        margin: 0 10px 10px 0;
        display: inline-block;
        padding: 1px 10px;
        border-radius: 15px;
        transition: background-color ease $animTime;

        &:hover {
            cursor: pointer;
            background-color: darken(#ededed, 15%);
        }

        .icon {
            max-width: 8px;
            padding-top: 7px;
        }

    }
}