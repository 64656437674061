.content {
    &.summary {
        form {
            margin: 0 auto;

            .summary {
                $tabPadding: 1rem 1.125rem;
                margin: 0 0 40px;

                legend {
                    font-size: 1.125rem;
                    font-weight: 500;
                }

                .checkbox-wrapper {
                    text-align: center;
                    
                    .checkbox-label {
                        color: black;
                        font-weight: bold;
                    }
                }

                &-row {
                    width: 100%;
                    background-color: transparent;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: $tabPadding;
                    border-radius: $borderRadius;
                    border: 1px solid $greyBorder;
                    transition: background-color ease 0.25s;

                    &[ng-click]:hover {
                        background-color: $greyLight;
                        font-weight: bold;
                        cursor: pointer;
                    }

                    .button {
                        margin: 0 0 0 40px;
                        white-space: nowrap;
                    }
                    
                    .smaller {
                        margin-bottom: 0;
                        margin-top: 0.125rem;
                    }

                    &.total {
                        color: $white;
                        background-color: $primaryColor;
                        border-radius: 5px;

                        &:hover {
                            background-color: rgba($primaryColor, 0.75);
                        }

                        .summary-label {
                            width: auto;
                            display: inline-block;
                        }
                    }
                }

                    &-label {
                        font-size: 1.125rem;
                        font-weight: 400;
                        line-height: 1.625rem;
                        margin: 0;

                        small {
                            font-size: 0.875rem;
                            font-weight: 300;
                            line-height: 1;
                        }
                    }

                    .flex-me {
                        justify-content: space-between;
                        flex-direction: row;
                        width: 100%;

                        + .smaller {
                            margin: 0.25rem 0 0 0;
                            width: 100%;
                        }
                    }

                    &-input {
                        margin: 0;
                        max-width: 300px;
                    }

                    &-price {
                        font-size: 1.125rem;
                        font-weight: 600;
                        line-height: 1;
                        margin: 0;

                        sub {
                            font-weight: 700;
                            line-height: 1;
                            bottom: 0;
                        }
                    }

                    &-card {
                        @include borderBlock($borderRadius, $greyBorder);
                        padding: 1.125em;
                        margin-bottom: 20px;

                        &.secondary {
                            border: none;
                            background-color: $greyLight;
                        }

                        .column :last-child {
                            margin-bottom: 0;
                        }

                        .address-block {
                            color: $greyText;
                            font-size: 0.875rem;
                            font-weight: 400;
                            line-height: 1.25rem;
                        }

                        &.total {
                            background-color: $green;
                            color: $white;

                            .summary-text {
                                color: inherit;
                                line-height: 0.875rem;
                                margin: 10px 0 0 0;

                                &.bolder {
                                    font-size: 1.125rem;
                                }
                            }
                        }

                    &__title {
                        font-family: $montserrat;
                        font-size: 1.125rem;
                        line-height: 1.25rem;
                        font-weight: 500;
                        margin: 0;

                        &.bolder {
                            font-size: 1.625rem;
                        }
                    }

                    &__smallTitle {
                        font-size: 0.9rem;
                        line-height: 1.25rem;
                        font-weight: 500;
                        margin: 0;

                        &.bolder {
                            font-size: 1.625rem;
                        }
                    }
                }

                &-text {
                    color: $greyText;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                }

                &-details {
                    font-size: 1.125rem;

                    .separator {
                        color: $green;
                        margin: 0 0.25rem;
                        font-size: 0.825rem;
                    }
                }
            }

            .submit-wrapper {
                text-align: center;
            }
        }
    }
}

@media (max-width: 768px){
    .content.summary {
        form {
            margin: 1rem;
            min-width:0;

            .summary legend, article .summary-label {
                font-size: 0.9375rem;
            }

            .summary {
                .checkbox-wrapper {
                    text-align: left;
                }
                .primary-btn {
                    margin-bottom: 120px;
                }
                &-row {
                    &.total {
                        flex-flow: row wrap;
                        justify-content: center;

                        .summary-label {
                            font-size: 1.125rem;
                        }

                        .summary-price, .summary-recap { /* pour surcharger la classe .text-right*/
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}