.siret {

    &-list {
        margin: 0;
        height: 394px; /* Height for 10 results max */
    }

    &-list-item {
        height: 25px;
        display: block;
        line-height: normal;

        &:first-child p{
            margin-top: 0;
        }

        &:last-child p {
            margin-bottom: 0;
        }

        &.ng-enter,
        &.ng-leave {
            opacity: 0;
            transform: translateX(10px);
            transition: all ease 150ms;
        }

        &.ng-enter-stagger {
            transition-delay: 50ms;
            transition-duration: 0s;
        }

        &.ng-enter-active {
            opacity: 1;
            transform: translateX(0);
        }

        .radio-label {
            height: 25px;
            display: flex;
            font-weight: normal;
            margin-right: 0;
            width: 100% !important;
        }

    }

    &-ko {
        .small-links {
            display: inline-block;
            margin-left: 10px;
        }
    }

}