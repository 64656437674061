.timeline {
    $itemPadding: 17px;

    &-wrapper {
        position: relative;
        max-width: 690px;
        margin: 0 auto;
    }

    &-list {
        margin: 0;
        position: relative;
        list-style-type: none;

        &.fully-filled:before {
            background-color: $green;
        }
    }

    &-item {
        position: relative;
        padding: $itemPadding 20px $itemPadding 60px;
        border-radius: $borderRadius;

        p {
            margin: 0;
        }

        i {
            height: 18px;
            width: 18px;
            position: absolute;
            top: 20px;
            left: 23px;
            z-index: 2;

            &:first-child {
                top: 35px;
            }

            &.ico-clock {
                background-image: url('../img/timeline-wait.png');
                background-repeat: no-repeat;
                background-size: contain;

                &:after {
                    content: "";
                    position: absolute;
                    height: 24px;
                    left: 7px;
                    top: 25px;
                    bottom: 0;
                    width: 4px;
                    background-color: $primaryColor;
                    border-radius: 0.156em;
                }

                &.first-clock {
                    &:after {
                        height: 47px;
                    }
                }

                &.last-clock {
                    &:after {
                        display: none;
                    }
                }
            }

            &.ico-alert {
                top: 30px;
                background-image: url('../img/alert-danger.svg');
                background-repeat: no-repeat;
                background-size: contain;

                &:after {
                    content: "";
                    position: absolute;
                    height: 52px;
                    left: 7px;
                    top: 25px;
                    bottom: 0;
                    width: 4px;
                    background-color: $primaryColor;
                    border-radius: 0.156em;
                }
            }
        }

        &:not(:first-of-type) {
            font-size: 0.875rem;
            line-height: 1rem;

            &.filled {
                .ico-checked:before {
                    content: "";
                    background-color: $white;
                    border-radius: 50%;
                    width: 23px;
                    height: 23px;
                    position: absolute;
                    left: -1px;
                    top: -3px;
                    z-index: -1;
                }
            }
        }

        &.alert {
            color: $alertColor;
            border: 1px solid $alertColor;
            background-color: $alertColorBackground;

            &:first-child {
                margin-top: 1rem;
            }

            &:before {
                content: "";
                position: absolute;
                height: 28%;
                left: calc(60px / 2);
                top: 0;
                bottom: 0;
                width: 4px;
                background-color: $primaryColor;
                border-radius: 0.156em;
            }
        }

        &.filled {

            .ico-checked {
                position: absolute;
                color: $white;
                font-size: 2rem;
                z-index: 99;
                top: 50%;
                left: 25px;
                transform: translateY(-50%) rotate(130deg);
                transform-origin: center;
            }

            &:after {
                content: "";
                background-color: $primaryColor;
                height: 100%;
                width: 4px;
                position: absolute;
                left: calc(60px / 2);
                top: 0;
            }

            &:first-child:after,
            &:last-child:after {
                height: 50%;
            }

            &.active:after {
                border-bottom-left-radius: 0.156em;
                border-bottom-right-radius: 0.156em;
            }

        }

        &:first-of-type {
            background-color: $primaryColor;
            padding: 27px 20px 27px 60px;

            .ico-checked {
                color: $white;
            }

            .item-content {
                color: $white;
                font-size: 1.125rem;
                line-height: 1.3125rem;
                font-weight: 400;
            }
        }

        &:last-of-type {

            /* Hide overflow of the :before background bar of timeline-list if last item contain more than 1 line, if not feel free to expand the height below */
            .ico-clock:before {
                content: "";
                background-color: $white;
                position: absolute;
                height: 200%;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 4px;
                transform: translateY(100%);
            }
        }
    }
}

@media (max-width: 768px) {
    .timeline {
        &-item {
            i {
                &.ico-clock {
                    &:after {
                        height: 50px
                    }
    
                    &.first-clock {
                        &:after {
                            height: 90px;
                        }
                    }
                }
    
                &.ico-alert {
                    &:after {
                        height: 80px;
                    }
                }
            }
            &.alert {
                &:before {
                    height: 25px;
                }
            }
        }
    }
}

@media (max-width: 570px) {
    .timeline {
        &-item {
            .button-corporate {
                font-size: 0.8125rem; /* pour iphone 5 et écrans de taille similaire*/
            }
        }
    }
}