$excPadding: 30px;

.exc {

    &-content {
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        transition: max-width ease $animTime;

        .tunnel-content__wrapper {
            height: calc(100% - 70px);
            max-height: 100%;

            @media (max-width: 1279px) {
                padding-left: 0 !important;
            }
        }

        .table-body{
            padding-bottom: 100px;
        }

        .table-body,
        .table-sort {
            margin: 0 -30px;
        }

        .table-list {
            padding: 5px 20px;
            transition: background-color ease $animTime;

            &.on-edit {
                background-color: #f8fcff;
            }
        }

        .table-sort {
            
            label {
                padding-left: 15px;
            }

            sort {
                // padding: 0 15px;
                display: block;
            }
        }

        .table-new-benef {
            padding: 0 15px;
            margin: 10px 0; 
        }


    }

    &-title {
        font-size: 1rem;
        font-weight: bold;
        color: $blueConnected;
        margin: 0;
    }

    &-sidebar {
        height: 100vh;
        position: absolute;
        left: 0;
        max-width: 60px;
        overflow: hidden;
        transition: max-width ease $animTime;

        + .exc-content {
            max-width: calc(100vw - 60px);
        }

        &.expanded {
            max-width: 230px;

            + .exc-content {
                max-width: calc(100vw - 230px);
            }

        }
    }

    > .column:not(.transparent) {
        padding: 15px $excPadding;
        background-color: $white;
        border: 1px solid $greyBorder;
        border-radius: $borderRadius;
    }

    .column.column-aside {
        padding: 0;
        background-color: transparent;
        border: none;
        max-width: 320px;
    }

    .detail-card {
        border: 1px solid $greyBorder;
        border-radius: $borderRadius;
    }

    > .column.transparent {
        padding: 0;
    }

    .table-list {
        background-color: $white;

        &:hover:not(.disabled) {
            background-color: $activeRowColorConnected;
        }
    }

    .summary-row {
        background-color: $white;
        border: 1px solid $greyBorder;


        &:not(.isolated) {
            margin-bottom: 0;
            border-radius: $borderRadius;
            border-bottom: 0;
        }

        &.isolated {
            margin-bottom: 15px;
        }
        // margin: 0 -30px; /* reset column padding exc layout to be collapse on border */

        &.total {
            flex-direction: column;
            background-color: $blueConnected;
        }

    }

    .radio-input + .radio-label:before,
    .checkbox-input:checked + .checkbox-label:after {
        background-color: $blueConnected;
    }

    .radio-input:checked + .radio-label:after {
        border-color: $blueConnected;
    }

    .header-actions-buttons {
        margin-bottom: 15px;
    }

}

.connected {

    .ui-view-wrapper {
        height: 100vh;
        position: relative;
        display: flex;
    }

    .feedly-nav-open-button,
    .feedly-item {
        background: $blueConnected;
    }

    .timeline-wrapper {
        background-color: $white;
        border-radius: $borderRadius;
        padding-bottom: 50px;
    }

    .timeline-item:first-of-type,
    .timeline-item.filled:after {
        background-color: $blueConnected;
    }

    .timeline-item.filled:not(:first-of-type) .ico-checked {
        color: $blueConnected;
    }

}
