.pagination {
    list-style-type: none;
    margin: $marginTopDefault 0;

    li {
        display: inline-block;
        background-color: transparent;
        margin: 0 5px;
        transition: background-color ease $animTime;

        &:first-child,
        &:last-child {
            padding: 0;
        }

        &.disabled {
            color: darken($greyText, 20%);
        }

        button {
            padding: 15px;

            &.disabled {
                color: darken($greyText, 20%);
                cursor: not-allowed;
            }
        }

        &.current {
            color: $white;
            background-color: $green;

            &:hover {
                background-color: lighten($green, 20%);
            }
        }

        &:hover {
            background-color: $greyLight;
        }
    }

}