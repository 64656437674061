.range {

  &-row {
    margin: 35px auto 55px !important;
    z-index: 0;
  }

  &-tooltips {
    font-family: $montserrat;
    background-color: $blueTitle;
    text-align: center;
    color: $white;
    font-size: 1em;
    font-weight: 700;
    line-height: 2.188em;
    padding: 0 20px;
    display: inline-block;
    border-radius: 0.125em 0.125em 0.125em 0;
  }

  &-description {
    text-align: center;
    font-size: 0.9375rem;
    margin: 50px -45px 0;
  }

  /* noUi-slider EXCV2 theme */

  &-exc {
    background-color: rgb(244, 244, 244);
    display: block;
    position: relative;


    .borne-min,
    .borne-max {
        font-weight: 500;
        color: #dadada;
        position: absolute;
        top: 50%;
    }

    .borne-min {
        left: -10px;
        transform: translateY(-50%) translateX(-100%);
    }

    .borne-max {
        right: -10px;
        transform: translateY(-50%) translateX(100%);
    }

    .noUi {

      &-base {
        background-image: none;
      }

      &-horizontal {
        height: 9px;
      }

      &-target {
        background-color: $blueTitle;
        // background: none;
        border-radius: 0.469em;
        border: none;
        box-shadow: none;
        // position: relative;
        // transition: background-image ease 0.3s;

        &.noUi-connect {
          box-shadow: none;
        }
      }

      &-origin {
          background-color: #f4f4f4;
          border-radius: 0.469em;
      }

      &-handle {
        background: $blueTitle;
        height: 30px;
        top: 50%;
        border: 1px solid rgb(198, 198, 198);
        box-shadow: $shadowCircle;
        position: absolute;
        border-radius: 50%;
        width: 30px;
        transform: translateY(-50%);
        cursor: pointer;

        &:before,
        &:after {
          content: none;
        }
      }

      &-connect {
        box-shadow: none;
      }

      &-background {
        box-shadow: none;
      }

      &-tooltip {
        background-color: $blueTitle;
        white-space: pre;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
        color: $white;
        border: none;
        border-radius: 0.125em;
        text-align: center;
        display: inline-table;
        padding: 8px 15px;
        position: absolute;
        bottom: 45px;
        top: auto !important;
        transform: translateX(-50%) translateX(15px);

        &:after {
            content: ' €';
            font-size: 0.625em;
            font-weight: 100;
        }

        &.tooltip-custom {
          top: -68px !important;
          margin-left: -17px;
          z-index: 9;
        }

        &:before {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 11px 5.5px 0 5.5px;
          border-color: $blueTitle transparent transparent transparent;
          position: absolute;
          bottom: -6px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

    }
  }

  &-simulation {

      &.green-theme {

          .noUi {

              &-tooltip {
                  background-color: $green;

                  &:before {
                      border-color: $green transparent transparent transparent;
                  }
              }

          }

      }

      &.blue-theme {

          .noUi {

              &-tooltip {
                  background-color: $blueTitle;

                  &:before {
                      border-color: $blueTitle transparent transparent transparent;
                  }
              }

          }

      }

      $textureColor1: #ffffff;
      $textureColor2: #f4beb2;

      .slider-limit {
          height: 40px;
          right: 2px;
          border: none;
        //   border-color: $greyBorder;
          z-index: 1;

          .limit-wrapper {
              height: 100%;
              width: 100%;
              position: relative;

            //   &:before {
            //       content: "";
            //       height: 300%;
            //       width: calc(100% + 20px);
            //       background-color: $white;
            //       position: absolute;
            //       top: 0;
            //       left: 26px;
            //   }

              &:after {
                  background-color: $textureColor2;
                  background-image: repeating-linear-gradient( 45deg, $textureColor1 0px, $textureColor1 2px, $textureColor2 2px, $textureColor2 6px);
                  content: "";
                  height: 9px;
                  width: calc(100% - 15px);
                  position: absolute;
                  left: 15px;
                  bottom: -9px;
                  right: 0;
              }
          }

          &:before {
              content: none;
          }

          .limit-text {
            //   transform: translateX(-50%) translateY(-100%);
            //   display: block;
            //   position: relative;
            text-align: right;
            font-size: 0.75rem;
            position: absolute;
            top: -22px;
            right: -7px;
            color: $orangeFlat;
            line-height: 1.2;

            //   &:before {
            //       content: "";
            //       height: calc(100% - 25px);
            //       position: absolute;
            //       left: 0;
            //       right: 0;
            //       bottom: 0;
            //       width: 1px;
            //       background-color: $greyBorder;
            //       margin: 0 auto;
            //       transform: translateY(100%);
            //   }
          }
      }

      .noUi-marker-horizontal.noUi-marker-normal {
          display: none;
      }

      .noUi-marker-horizontal.noUi-marker-large {
          background-color: $blueTitle;
          position: absolute;
          top: 0;
          height: 32px;
          border-radius: 50%;
          width: 32px;
        //   margin-left: -2px;
          transform: translateX(-50%) translateY(-50%) translateY(4px);
      }
  }
}

.noUi-horizontal .noUi-handle {
    height: 34px !important;
    top: 4px !important;
}

.noUi-value-horizontal {
    padding-top: 15px !important;
    position: absolute;
    transform: translateX(-50%);
    font-weight: bold;
}

.slider {

    &-limit {
        border-left: 1px solid $green;
        position: absolute;
        right: 0;
        width: auto;
        bottom: 0;
        padding-bottom: 9px;
        z-index: 1;
        transition: width ease 0.1s;
        // transform: translateX(100%);

        &:before {
            content: "";
            height: 9px;
            width: 100%;
            border-top-right-radius: 0.469em;
            border-bottom-right-radius: 0.469em;
            background-color: $green;
            bottom: 0;
            position: absolute;
        }
    }

    &-group {
        margin-bottom: 1.25rem;
        align-items: center;

        > .column:first-of-type {
            padding-left: 0;
        }

        > .column:last-of-type {
            padding-right: 0;
        }

        input {
            margin-bottom: 0 !important;
        }

        .limit-value {
            padding: 0;

            &:first-of-type {
                text-align: right;
            }

            &:last-of-type {
                text-align: left;
            }

        }

    }
}

.noUi-value-horizontal {
    padding-top: 12px;
}

.limit-exo-value {
    // padding-top: 32px;
    display: none;
}

.limit-exo-bubble {
    background-color: #ff9983 !important;
    border: 3px solid $orangeFlat;
    z-index: 99;
}

.noUi-connect .noUi-background {
  background-color: rgba(244, 244, 244, 1);
}

@media (min-width: 768px) {
    .range-description {
        .line-break {
            display: none;
        }
    }
}
