.breadcrumb {
    position: relative;
    margin-bottom: $tunnelPaddingConnected;

    &-link {
        font-size: 1rem;
        font-family: $montserrat;
        color: $greyText;
        padding: 0 20px;
        margin: 0.5rem 0;

        > a,
        > span {
            display: inline-block;
            height: 100%;
            line-height: 1.4;
        }

        > a {
            padding: 0 8px;
            position: relative;

            &:before {
                background-color: $blueConnected;
                content: "";
                height: 3px;
                width: 100%;
                position: absolute;
                bottom: -15px;
                left: 0;
                transform: scaleX(0);
                transition: transform ease $animTime*3;
            }
        }

        &:first-of-type {
            padding-left: 0;
            border-left: none;
        }

        &:last-of-type {
            position: relative;

            &:after {
                height: 109px;
                content: "";
                background-image: url('../img/ticket-resto-carte-nfc-very-small-rotated.png');
                background-size: cover;
                background-position: center;
                position: absolute;
                top: -10px;
                right: -10px;
                width: 150px;
                transform: translateX(100%);
            }
        }

        .icon {
            fill: $greyText;
            transform: rotate(-90deg);
            vertical-align: middle;
            transform-origin: center;
            max-width: 18px;
            margin: -2px 0 0 25px;
        }

        &.filled {
            color: $greenLemon;
            
            > a {

                &:before {
                    background-color: $greenLemon;
                }
            }

            &:hover {
                            
                > a {
                    color: inherit;

                    &:before {
                        transform: scaleX(1);
                    }
                }
            }

            .icon {
                fill: $greenLemon;
            }
            
            .breadcrumb-counter {
                background-color: $greenLemon;
                border: 1px solid $greenLemon;
                color: $greenLemon;
                
                &:before {
                    opacity: 1;
                    transform-origin: center;
                    transform: rotate(-45deg) scale(0.9);
                }
            }
        }

        &.active {
            color: $blueConnected;
            
            > a {

                &:before {
                    transform: scaleX(1);
                }
            }

            .icon {
                fill: $blueConnected;
            }

            .breadcrumb-counter {
                background-color: $blueConnected;
                border: 1px solid $blueConnected;
                color: $white;
            }

            .breadcrumb-counter {
                font-weight: normal;
            }

        }

    }

    &-counter {
        height: 25px;
        background-color: transparent;
        border: 1px solid $greyText;
        color: $greyText;
        text-align: center;
        width: 25px;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
        border-radius: 50%;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: 5px;
            top: 6px;
            display: table;
            height: 8px;
            width: 14px;
            border-width: 3px;
            border-style: solid;
            border-top: 0;
            border-right: 0;
            border-color: $white;
            background-color: transparent;
            transform: rotate(-45deg) scale(0);
            transition: transform ease 0.2s;
        }

        
    }

    &-label {
        letter-spacing: 3px;
        vertical-align: middle;
        font-weight: normal;
        font-size: 0.875rem;
    }
    
    &-info {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }
    

}
