body {
    font-family: $montserrat;
    font-size: 0.875em;
    margin: 0;
    padding: 0;
    height: 100vh;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    overflow: hidden;
    background-color: #f9fafc;

    &.overflow-body {
        overflow: hidden;
    }
}

h1 {
    line-height: 1;
}

a {
    text-decoration: none;
    color: inherit;
}

small {
    font-size: 0.75rem;
}

strong {
    font-size: 1em;
    font-weight: 600;
}

em {
    font-weight: 700;
}

p {
    font-size: 1rem;
    margin: 1em 0;
    word-break: break-word;
}

a[ng-click]{ /* preventDefault ng $event */
    cursor: pointer;
}

@media(max-width: 768px){
    body {
        overflow: auto;
    }
}
