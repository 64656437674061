.simulation {

  select {
    width: auto;
  }

  .small-box {
    margin: 0;
  }

  .submit-wrapper {
    width: 100%;
  }

  &-counter {
    margin: 0 auto 20px;

    .column {
      display: flex;
      align-items: baseline;
    }

    .description {
      font-size: 1.5rem;
    }

    .material-counter {
      align-self: flex-start;
    }
    
  }

  &-recap {      
      text-align: center;
      padding: 15px;
      max-width: 70%;
      margin: 0 auto 35px;
      border: 1px solid $greyBorder;
      display: flex;
      min-width: 760px;

      .h3 {
          font-weight: 400;
      }

      input {
          height: 100% !important;
          color: #f26c52;
          font-weight: bold;
          font-size: 1.5rem;
          text-align: center;
          padding: 10px 15px 0;
          border: none;
          box-shadow: none;
          margin: 0;
          border-bottom: 1px solid $greyBorder;
      }

      .description {
          font-weight: 400;
          font-size: 1.5rem;
          line-height: 45px;
      }



      a {
          color: $blue;
          font-size: 0.875rem;
      }
  }

  &-thead {
    // border-top: 1px solid $greyBorder;
    // border-bottom: 1px solid $greyBorder;
    padding-top: 35px;

    .title {
      font-size: 1.125rem;
      font-weight: bold;
      margin-bottom: 1rem !important;
    }

    .column > p {
      margin: 5px 0;
    }

    // ~ .simulation-economy:last-child {
    //   border-bottom: 1px solid $greyBorder;
    // }
  }

  &-highlight {

    .title {
      font-size: 1.125rem;
      font-weight: bold;
    }

    .economy-result {
      margin-bottom: 0.5rem;
    }

    .smaller {
      margin: 0.125rem 0;
      font-size: 0.75rem;
      font-weight: 300;

      &.orangeC {
        font-weight: 500;
      }
    }

  }

  .icon-team-big {
      max-width: 4rem;
      max-height: 4rem;
  }

  .icon-user-big {
        max-width: 4rem;
        max-height: 4rem;
  }

  &-economy {
      align-items: flex-end;
      padding: 20px 0px;

      p {
        margin-bottom: 1rem;
      }

      &:nth-child(even) {
          background-color:#f4f4f4;
      }

  }
  &-table {
    border-top: 1px solid $greyBorder;
    margin-top: 35px;
    width: 100%;

    .large-2 {
      .icon {
        max-height: 3rem;
        max-width: 3rem;
      }

      .sprite-user-big {
        transform: scale(0.7);
      }

      p {
        margin-top: 0;
        font-size: 0.75rem;
      }
    }

    // .simulation-economy:last-child {
    //   border-bottom: 1px solid $greyBorder;
    // }

  }
}

.economy {
  font-size: 1rem;
  text-align: center;

  &-title {
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.75em;
  }

  &-result {
    font-size: 1em;

    strong {
      color: $orangeFlat;
      font-size: 1.625rem;
      font-weight: 700;
      line-height: 1.875rem;
      display: block;
    }

    small {
      font-weight: 300;
      font-size: 0.75rem;
      line-height: 0.875rem;
    }

  }

  p {
    font-size: 0.875em;
    font-weight: 300;
    line-height: 1.25rem;
  }
}
