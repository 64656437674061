.accordion {

    &-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    &-item {
        margin-bottom: 10px;
    }

    &-button {
        font-weight: 500;
        text-align: left;
        position: relative;
        display: block;
        width: 100%;

        .icon-small-arrow {
            right: -10px;
            transform: translateY(-50%) rotate(-90deg);
        }

        &[aria-expanded='true'] {
            .icon-small-arrow {
                transform: translateY(-50%) rotate(0);
            }
        }
    }

    &-content {
        margin-bottom: 0;
        height: auto;
        max-height: 0;
        overflow: hidden;
        transition: max-height ease $animTime*2;

        p {
            font-size: 0.875rem;
            margin: 0.5rem 0;
        }

        &[aria-hidden='false'] {
            max-height: none;
        }
    }

}
