.testimonial {
    padding: 0;
    border: none;
    position: relative;
    align-items: center;
    flex-flow: row nowrap;
    margin: 50px auto 90px;

    .quote {
        align-self: flex-start;
        // color: rgb(175, 175, 175);
        color: rgb(243, 243, 243);
        font-family: Georgia;
        // opacity: 0.15;
        font-size: 9.375rem;
        font-weight: 400;
        line-height: 1;
    }

    &-author {
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 300;
        line-height: 1.188rem;

        /* Remove of the -- mark insert by default (content: '\2014 \0020';) */
        &:before {
            content: none;
        }

        strong {
            color: $blueTitle;
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.688rem;
            display: block;
        }

    }

    &-quote {
        font-weight: normal;
        font-size: 1.25rem;
        font-style: italic;
        line-height: 2.25rem;
        margin-bottom: 0;
    }

    &-image {
        height: 120px;
        position: relative;
        flex: 0 0 120px;
        width: 120px !important;
        margin:0 40px 0 0;
        display: block;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 1;
    }
}

@media(max-width: 768px){
    .testimonial {
        padding: 1rem;
        flex-flow: row wrap;
        align-items: end;

        &-image {
            height: 5rem;
            width: 5rem !important;
            max-width: 5rem;
            margin: 0 1rem 0 0;
        }

        &-content {
            &.small-9 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        .quote {
            display: none;
        }
    }
}
