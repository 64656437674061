.cms {
    height: calc(100vh - 20px);

    textarea {
        line-height: 1.4 !important;
    }
}

.wording-header {
    font-size: 1.5rem;
    color: $white;
    text-transform: uppercase;
    margin: 20px 0 0;

    > .row {
        background-color: $blueConnected;
    }
}

.actions-wording {
    .button {
        margin-bottom: 0;
    }
}

.button-refresh-cache {
    background-color: $greyMedium;
    padding: 20px;
    position: fixed;
    top: 20px;
    right: 20px;

    h3 {
        text-align: center;
        font-size: 1.175rem;
        margin-bottom: 20px;
    }
}

.wording-presentation {
    line-height: 40px;
    border-bottom: 1px solid $greyBorder;

    .columns {
        line-height: 1.4;
        border-right: 1px solid $greyBorder;
        padding: 10px;

        &:first-child {
            display: flex;
            border-left: 1px solid $greyBorder;
            font-weight: bold;
        }

        &:nth-child(odd) {
            background-color: rgba(238, 238, 238, 0.34);
        }
    }
}

.exempleClassWording {
    border: 2px solid black;
    color: darkgreen;
    width: 150px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    background-color: lightgreen;
}
.exempleClassWording2 {
    border: 1px dashed red;
    color: navy;
    width: 150px;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    font-style: italic;
}
.exempleClassWording3 {
    border: 2px solid #0094ff;
    color: #043951;
    width: 150px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
    background-color: lightblue;
}
