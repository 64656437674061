.detail-card {
    align-self: flex-start; /* Make height auto on block in flex layout */
    margin-left: 15px;
    padding: 0 !important;
    max-width: 320px;

    &-row {
        background-color: $white;
        padding: 15px 30px;
        margin: 0 auto;
        border-bottom: 1px solid $greyBorder;

        &:last-child {
            border-bottom: 0;
        }
    }

    &-title {
        font-family: montserrat;
        font-size: 1rem;
        font-weight: lighter;
        color: $blueConnected;
        margin-bottom: 0;
        border-bottom: none;
    }

    &-subtitle {
        color: $greyDarker;
        font-size: 0.875rem;
        font-weight: 400;
        font-family: $montserrat;
        margin-bottom: 0.25rem;
    }

    &-amount {
        color: $blueConnected;
        font-size: 1.875rem;
        font-weight: bold;
        line-height: 1;
        margin: 0;
    }

    .total {
        background-color: $blueConnected;
    }
}
