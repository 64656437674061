.nav {

    &-list {
        list-style-type: none;
        position: relative;
        padding: 0 40px;
        margin: 0;

        .link-content {
            padding-left: 10px;
            display: table-cell;
            vertical-align: middle;
        }

        .title {
            color: $blueTitle;
            font-family: $montserrat;
            font-size: 0.9375rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }

        a {
            display: block;
            color: black;
            font-weight: 300;
            margin-bottom: 0.875rem;
            line-height: 1;
            font-size: 0.8125rem;

            .icon,
            .link-content {
                display: inline-block;
                vertical-align: middle;
            }
        }

        .flex-me {
            flex-wrap: wrap;
            justify-content: space-between;

            a {
                flex: 0 0 115px;
            }
        }

        &:not(:first-child):before {
            content: "";
            background-color: lightgrey;
            height: 90%;
            top: 50%;
            position: absolute;
            left: 0;
            width: 1px;
            transform: translateY(-50%);
        }
    }

}
