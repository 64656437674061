$delayStepper: 0.5s;

.stepper {

  &-wrapper {
    position: relative;
  }

  &-section {
    margin-bottom: 1.875rem;
    position: relative;
    overflow: hidden;
    color: $blueTitle;

    &:before { 
      content: "";
      position: absolute;
      height: 200%;
      left: 5px;
      top: 0;
      bottom: 0;
      width: 3px;
      background: $edPrimaryHover; /* For IE9 fallback */
      background: linear-gradient( to bottom, $edPrimaryHover 0%, $edPrimaryHover 50%, $greyStepper 50%, $greyStepper 100% );
      background-position: 0% 100%;
      background-size: 100% 200%;
      border-radius: 0.156rem;
      transition: background-position ease $delayStepper*2;
    }

    &.fully-filled:before {
        background: $edPrimaryHover; /* For IE9 fallback */
        background: linear-gradient($edPrimaryHover 0%, $edPrimaryHover 50%, $greyStepper 50%, $greyStepper 100%);
        background-position: 0% 0%;
    }

  }

  &-nav {
      p {
          margin: 0;
      }
  }

  &-link {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1;
    display: block;
    margin: 0 0 0.625rem;
    padding-left: 20px;
    min-width: 140px;
    text-align: left;
    position: relative;
    transition: all ease $animTime;

    &:not(.filled):not(.active) {
      cursor: initial;
    }

    .link-content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      max-width: calc(100% - 15px);
    }

    &:before,
    &:after {
        content: '';
    }

    &:after {
        height: 0;
        background: linear-gradient( to bottom, $edPrimaryHover 0%, $edPrimaryHover 50%, transparent 50%, transparent 100% );
        background-position: 0% 100%;
        background-size: 100% 200%;
        transition: background-position ease $delayStepper;

        height: 1rem;
        width: 3px;
        position: absolute;
        top: -11px;
        left: 5px;
    }

    &:before {
        background-color: #d6d6d6;
        height: 7px;
        width: 7px;
        border-radius: 50%;
        position: absolute;
        left: 3px;
        top: 50%;
        transform: translateY(-50%);
        transition: background-color ease $animTime;
    }

    .ico-checked {
      position: absolute;
      color: $edPrimaryHover;
      font-size: 1rem;
      z-index: 99;
      top: -1px;
      left: 2.5px;
      transform: rotate(130deg) scale(0);
      transform-origin: center;
      transition: transform $bounce $animTime $delayStepper;
    }

    &.filled {

      &:before {
        background-color: $white;
        height: 100%;
        width: 9px;
        left: 2px;
        z-index: 9;
      }

      .ico-checked {
          transform: rotate(130deg) scale(1);
      }

      &:first-child:after {
        background: $edPrimaryHover; /* For IE9 fallback */
        height: 42px;
        top: -38px;
        border-top-left-radius: 0.156rem;
        border-top-right-radius: 0.156rem;
      }

      &:after {
        background-position: 0% 0%;
      }

    }

    &:first-child.active:after {
      height: 42px;
      top: -38px;
      border-top-left-radius: 0.156rem;
      border-top-right-radius: 0.156rem;
    }

    &.active {

      &:before {
        background-color: $edPrimaryHover;
        transition-delay: $delayStepper;
      }

      &:after {
        background: $edPrimaryHover;
        background-position: 0% 0%;
      }

      &:first-child:after {
        height: 42px;
        top: -38px;
        border-top-left-radius: 0.156rem;
        border-top-right-radius: 0.156rem;
      }

    }

    &.current {

      .link-content:after {
        background-color: white;
        content: "";
        height: 6px;
        width: 6px;
        top: 50%;
        border-top: 1px solid $blueTitle;
        border-right: 1px solid $blueTitle;
        transform: translateY(-50%) rotate(45deg);
        position: absolute;
        margin-left: 10px;
        right: 2px;
      }

    }


  }

  &-title {
    font-weight: 700;
    margin-bottom: 1rem;
    padding-left: 20px
  }

}
