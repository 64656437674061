.badge {

    &-v2 {
        width: 4.625rem;
        height: 4.625rem;
        border: 2px solid $blueConnected;
        border-radius: 0.525rem;
        border-bottom-left-radius: 0;
        display: flex;
        text-align: center;
        margin-right: 20px;

        .inner-text {
            color: $blueConnected;
            font-family: $montserrat;
            font-size: 2.25rem;
            font-weight: 300;
            text-align: center;
            display: block;
            margin: auto;
        }
    }
}