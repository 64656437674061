$paddingBox: 18px;

%base {
    font-size: 0.875rem;
    line-height: normal;
    border-style: solid;
    border-width: 1px;
    border-radius: $borderRadius;
    padding: $paddingBox $paddingBox $paddingBox 60px;
    position: relative;
}

@mixin baseBefore($iconWidth: 20px) {
    content: "";
    background-size: contain;
    height: $iconWidth;
    position: absolute;
    top: 50%;
    left: calc(#{$paddingBox} + (#{$iconWidth} / 2));
    width: $iconWidth;
    transform: translateX(-50%) translateY(-50%);
}

.box {

    &-alert {
        color: $alertColor;
        border-color: $alertColor;
        background-color: $alertColorBackground;
        @extend %base;

        &:before {
            background-image: url(../img/alert-danger.svg);
            @include baseBefore(30px);
        }
    }

    &-info {
        color: $infoColor;
        border-color: $infoBorderColor;
        background-color: $infoBackgroundColor;
        @extend %base;

        &:before {
            background-image: url(../img/info.svg);
            @include baseBefore(30px);
        }
    }
}