#footer-mobile {
    display: none;
}
.footer {

    &-trigger {
        height: 60px;
        background-color: $white;
        position: relative;
        cursor: pointer;
        transform: translateY(0);

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            box-shadow: 0 5px 15px rgba(0,0,0,0.3);
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        // .icon-client-service {
        //     height: 41px;
        //     width: 39px;
        // }
        
        .icons-up {
            transition: stroke ease $animTime;
        }

        &:hover {

            &:before {
                opacity: 1;
            }

            .icon-up {
                stroke: #444 !important;
                animation-name: scaleInUp;
                animation-duration: 0.75s;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-timing-function: ease-in-out;
            }

        }

        &.ng-hide-add {
            transform: translateY(0%);
            transition: all linear 350ms;

            &.ng-hide-add-active {
                transform: translateY(10%);

                .reassurance .column {
                    opacity: 0;
                }
                
            }
        }

        &.ng-hide-remove {
            transition: all linear 350ms;
            transform: translateY(100%);
            transition-delay: 350ms;

            &.ng-hide-remove-active {
                display: block;
                transform: translateY(0%);
            }
        }

        .footer-toggle {
            position: absolute;
        }

        .reassurance {
            top: 50%;
            right: 0;
            left: 0;
            width: 100%;
            margin: 0 auto;
            position: absolute;
            transform: translateY(-50%);
            color: $blueTitle;
            font-weight: bold;
            font-size: 0.9375rem;

            @include breakpoint(medium down) {

                p {
                    display: none;
                }

            }
            
            .icon {
                display: table-cell;
                vertical-align: middle;
                margin: auto 1.25rem auto 0;
                padding: 0;
                // margin-right: 0;

                &-commercial {
                    height: 26px;
                    width: 26px;
                }

                &-3dsecure {
                    max-width: 40px;
                }

                + p {
                    text-align: left;
                }
            }

            .column {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                opacity: 1;
                transition: opacity ease $animTime;

                @for $i from 1 through 4 {
                    &:nth-child(#{$i}) {
                        transition-delay: 50ms * $i;
                    }
                }

                i {
                    margin: auto 1.25rem;
                }
            }

            .icon-commercial {
                height: 26px;
                width: 26px;
            }

            .icon {
                display: table-cell;
                vertical-align: middle;
            }

        }
    }

    &-main {
        max-height: $footerClosedHeight;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 998;

        .footer-toggle i {
            position: relative;
            margin: 0 auto;

            .icon-close {
                opacity: 0;
                transform: rotate(0deg);
                transition: opacity ease 0.5s, transform ease 0.5s;
            }

            &:before {
                content: "";
                width: 50%;
                height: 50%;
                position: absolute;
                top: 0;
                right: 0;
                border-top: 2px solid darken($greyBorder, 20%);
                border-right: 2px solid darken($greyBorder, 20%);
                transform-origin: bottom;
                transform: rotate(-45deg);
                opacity: 1;
                transition: transform ease 0.5s, opacity ease 0.5s;
            }

        }

        .reassurance {

            .flex-me {
                position: relative;
                margin-bottom: 2em;
                opacity: 0.7;
                transition: all ease $animTime;
            }

            p {
                text-align: center;
                font-size: 0.875rem;
                margin: 0;
            }

        }

        &.open {
            // z-index: 9999;

            // .to-animate {
                // opacity: 1;
                // transform: translateY(0);

                // &.testimonial {
                //     transition-delay: 450ms; 
                // }
            // }

            .footer-toggle {
                margin: 2em auto;

                @media(max-width:767px){
                    margin: 5em auto 2em auto;
                }
                @media(min-width:768px){
                    margin: 2em auto;
                }
                


                i {

                    .icon-close {
                        fill: rgba($blueDark, 0.5);
                        opacity: 1;
                    }

                    &:before {
                        opacity: 0;
                    }
                }
            }
        }
    }

    &-nav {
        background-color: #f5f7fa;
        padding: 45px 0;

        .row {
            justify-content: space-between;
        }

        &-text {
            display: block;
            color: #808080;
            font-size: 0.75rem;
            font-weight: 300;
            margin-bottom: 0.875rem;
            line-height: 1;
        }
    }

    &-content {
        background-color: $white;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(100%);
        z-index: 10;
        max-height: 100vh;
        overflow: hidden;
        // transition: transform 350ms cubic-bezier(0.465, -0.070, 0.000, 1.14);
        transition: transform 350ms ease-in-out;

        .reassurance {
            overflow: hidden;

            &-icon {
                display: block;
                text-align: center;
            }

            .flex-me {
                flex-direction: column;
                opacity: 1;
            }

            * {
                transition: all ease $animTime*3;
            }            

            // &-icon,
            // &-title,
            // &-text {
            //     opacity: 0;
            //     transform: translateY(-20px);
            // }

            // &-icon {
            //     transition-delay: 450ms;
            // }

            // &-title {
            //     transition-delay: 500ms;
            // }

            // &-text {
            //     transition-delay: 550ms;
            // }

        }
        
        .to-animate {
            opacity: 0;
            transform: translateY(-20px);
            transition: opacity ease $animTime*3, transform ease $animTime*3 0.1s;

            &.reassurance-icon {
                transition-delay: 450ms;
            }

            &.reassurance-title {
                transition-delay: 500ms;
                font-weight: bold;
                color: $blueTitle;
            }

            &.reassurance-text {
                transition-delay: 550ms;
            }

            &hr {
                transition-delay: 600ms;
            }

            &.testimonial {
                transition-delay: 650ms;
            }

            &.footer-nav {
                transition-delay: 650ms;
            }
        }

        &.open {
            transform: translateY(0);
            transition-delay: 200ms;

            .to-animate {
                opacity: 1;
                transform: translateY(0);
            }
        }

    }

    &-toggle {
        margin: 1em auto 0;
        text-align: center;
        width: 100%;
        outline: none;
        z-index: 1;
    }

    &-overflow {
        height: 100vh;
        content: "";
        background-color: rgba(black, 0.57);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 997;
        cursor: pointer;

        &.ng-hide-add {
            transition: 0.25s linear opacity 400ms;
            opacity: 1;
        }

        &.ng-hide-add-active {
            opacity: 0;
        }

        &.ng-hide-remove {
            transition: 0.25s linear opacity;
            opacity: 0;
        }

        &.ng-hide-remove-active {
            opacity: 1;
        }
    }
}

@keyframes scaleInUp {
    
    from {
        transform: translateY(0) scale(1);
    }

    to {
        transform: translateY(-30%) scale(1.1);
    }

}

@media(max-width: 768px){
    #footer-desktop {
        display: none;
    }
    #footer-mobile {
        display: block;
    }
    .footer-main {
        position: fixed;
        
        .reassurance {
            .reassurance-header {
                margin-top: 1rem;

                &:first-child {
                    margin-top:01;
                }
            }
        }
    }
    .footer-nav {
        background-color: #f9fafc;

        &.to-animate {
            &.for-nav-mobile {
                .nav-list {
                    flex: none;
                    min-width: 100%;
                    text-align: left;
                    padding: 1rem;

                    .row {
                        .title {
                            color: $blueTitle;
                            font-size: 0.9375rem;
                            font-weight: 600;
                        }

                        img {
                            height: 1rem;
                        }
                        
                        .rotatingImg {
                            transform: rotate(180deg);
                        }
                    }

                    .subtitle {
                        .title {
                            color: $blueTitle;
                        }

                        a {
                            color: $greyText;
                            font-size: 0.75rem;
                        }
                    }

                    &:not(:first-child):before {
                        background-color:#f9fafc;
                    }
                }

                .border-bottom {
                    border-bottom: 1px solid lightgrey;
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }
    }
}
