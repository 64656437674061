.info {

  &-card {
    text-align: center;
    padding: 1.625rem 0.3125rem;
    width: 150px;
    margin: 0 auto 30px;
    border: 1px solid $greyBorder;

    .smaller {
      font-size: 0.9375rem;
      line-height: 1.2;
      margin: 0.5rem 0;
    }
  }

  &-link {
    padding: 20px;
    position: relative;
    display: block;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      border: 1px solid $greyBorder;
      box-shadow: $shadowOverlay;
      transition: opacity ease $animTime;
    }

    .title {
      color: $blueConnected;
      font-size: 1.075rem;
      font-weight: bold;
      margin: 0 0 0.5rem 0;
    }
    img {
      height: 28px;
      display: block;
      margin: 0 0 1rem 0;
    }
    p {
      font-size: 0.875rem;
      margin: 0.25rem 0;
      color: $greyText;
    }
    .icon {
      fill: $greyText;
      max-width: 0.625rem;
      max-height: 0.625rem;
    }

    &:hover {

      &:before {
        opacity: 1;
      }

      .icon {
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-name: moveOnRightLight;
      }

    }
  }

  &-price {
    color: $green;
    font-size:3.25rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;

    sub {
      font-weight: 300;
      font-size: 40%;
      bottom: -1px;
    }
  }

}
