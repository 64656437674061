.dropdown {

    &-menu {
        position: relative;
    }

    &-content {
        background-color: $white;
        list-style-type: none;
        position: absolute;
        top: calc(50% + 24px);
        right: 7px;
        margin: 0;
        padding: 0.875rem 1rem;
        border-radius: 0.125em;
        border: 0.125em solid $greyBorder;
        box-shadow: 2px 2px 2px #ccc;
        min-width: 120px;
        z-index: 999;
    }

}
