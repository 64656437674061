.burger {

  &-button {
    font-size: 0.75em;

    &-ico {
      display: none;
      position: relative;
      height: 1.25em;
      width: 2em;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='30px' height='30px' viewBox='0 0 30 30' enable-background='new 0 0 30 30' xml:space='preserve' stroke='redgrey' fill='grey'><rect width='30' height='3'/><rect y='24' width='30' height='3'/><rect y='12' width='30' height='3'/></svg>");
      background-size: contain;
      z-index: 2;
      cursor: pointer;

      @media(max-width: 1024px){
        display: block;
      }
    }
  }

  &-menu {
    height: auto;
    color: $white;
    border-right: 1px solid rgba($greyDarker, 0.2);
    z-index: 999;

    &.open {
      transform: translate3d(0, 0, 0);
    }

    .icon {
      margin: 2px 10px 0 0;
    }

    .contract {
        padding: 10px 20px;

        .icon-small-arrow:before,
        .icon-small-arrow:after {
            background-color: $white;
        }

      &-link {
        font-size: 1em;
        color: $blueDark;
        display: flex;
        align-items: center;
      }

      small, strong {
        display: block;
      }
    }

    section {
      padding: 20px;
      border-bottom: 1px solid rgba($greyDarker, 0.2);
    }
  }

  &-link {
    color: rgba($greyDarker, 0.5);
    transition: color ease 0.35s;
    display: flex;
    align-items: flex-start;
    padding: 10px 0;

    .icon-small-arrow {
        margin-top: 0;
    }

    &:hover {
      color: $greyDarker;
    }
  }
}

.help {

    &-sidebar {
        overflow: scroll;
        background-color: $white;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        width: $feedlyWidth;
        z-index: 9999;
        transform: translateX(100%);
        // transition: transform $animSidebar 0.35s;
        transition: transform ease-in-out 0.35s;
        transition-delay: 0s;
        transform-style: preserve-3d;
        backface-visibility: visible;
        box-shadow: $shadowOverlay;

        .overflow {
            background-color: rgba($black, 0.77);
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            bottom: 0;
            width: calc(100vw - #{$feedlyWidth});
            visibility: hidden;
            opacity: 0;
            transform: translateX(-100%);
            transform-origin: center;
            transition: opacity ease 0s 0s;
        }

        &.open {
            transform: translateX(0);
            transition-delay: 0.25s;

            h3 {
                color: $blueTitle;
            }

            .overflow {
                visibility: visible;
                opacity: 1;
                transition: opacity ease $animTime $animTime;
            }
        }

        @media(max-width: 768px){
            &.open {
                width: 100%;
                overflow: auto;
            }
        }

    }

    &-header {
        position: relative;
        padding: 30px;
        border-bottom: 1px solid $greyBorder;

        .close {
            height: 1rem;
            text-align: right;
            top: 20px;
            right: 20px;
            position: absolute;
            width: calc(100% - 40px);

            .icon {
                position: relative;
                transform: rotate(0);
                transform-origin: center;
                transition: transform ease $animTime;

            }
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                width: 1rem;
                height: 1rem;
                background-color: rgba($greyDarker, 0.2);
                border-radius: 50%;
                margin-top: -0.5rem;
                transform: scale(0);
                transition: transform $bounce $animTime;
            }

            &:hover {

                .icon {
                    stroke: $white;
                    fill: $white;
                    transform: rotate(180deg);
                }

                &:before {
                    transform: scale(2);
                }
            }
        }

        .h3 {
            margin: 0;
            font-weight: 700;
            color: $blueTitle;
        }
    }

    &-content {
        padding: 20px 30px;

        & .passerAuPrelevementError {
            &-text {
                margin: 0 0 1rem 0;
                font-weight: bold;
            }
            & .button {
                margin-top: 4rem;
            }
        }

        h2 {
            margin-bottom: 20px;
            font-size: 1.25rem;
            color: $blueTitle;
        }

        h4 {
            color: $blueTitle;
        }

        .summary-card {
            border: 1px solid lightgrey;
            padding: 14px;
            margin-bottom: 15px;

            .column.alignR {
                font-weight: bold;
            }

            &.total {
                color: white;
                background-color:#72b4dc;
            }
        }

        @media(max-width: 768px){
            .summary-card {
                .column {
                    flex: auto;
                }
            }
        }
    }

    &-actions {
        margin: 0 -10px;

        .icon-table {
            margin: auto 0.75rem auto 0;
        }
    }
}

.sidebar {

    &-overflow {
        background-color: rgba($black, 0.77);
        position: fixed;
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        width: 100vw;
        z-index: 999;
        transition: opacity ease-in-out 0.15s;
        transition-delay: 0;

        &.ng-hide-remove,
        &.ng-hide-add-active {
            opacity: 0;
            transition-delay: 0;
        }

        &.ng-hide-add-active {
            transition-delay: $animTime*2;
        }

        &.ng-hide-active {
            opacity: 1;
        }

        &.blue {
            background-color: rgba($blueConnected, 0.8) !important;
        }

        &:hover {
            cursor: pointer;
        }
    }

}

.exc-sidebar {
    background-color: $blueConnected;
    position: relative;
    width: 100%;

    .connection {
        position: absolute;
        bottom: 0;
        border: none;
        padding-bottom: 0; 
    }

    .icon {
        stroke: $white;
    }

    .burger {

        &-menu {
            height: 100vh;
            display: flex;
            flex-direction: column;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            position: relative;
            transition: max-width ease $animTime;

            .connected-link {
                position: absolute;
                bottom: 5px;
                right: 0;
                left: 0;
                margin: 0 auto;
                padding: 0 20px;

                .icon-user {
                    stroke: $white;
                    margin: 0;
                }
            }

        }

        &-link{
            color: $white;
            position: relative;
            padding-left: 26px;

            > .icon:first-child {
                position: absolute;
                left: 0;
                top: 10px;
            }

        }

        &-content {
            max-height: 21px;
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            transition: opacity ease $animTime $animTime;
        }
    }

    .accordion {

        &-item {
            margin: 0;
        }

        &-button {
            display: flex;
            align-items: flex-end;

            .icon {
                position: absolute;
                // margin: 0;
            }

            // &[aria-expanded='true'] + .accordion-content{
            //     margin-top: 0.5rem;
            // }

        }

        &-content {
            margin-left: 26px;
        }

    }

    .burger-menu-header {
        height: 60px;
        background-color: rgba(28, 33, 72, 1);
        display: flex;
        position: relative;
        cursor: pointer;
        flex-direction: column;

        .logo-big {
            height: auto;
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            width: 115px;
            margin: 0 auto;
            opacity: 0;
            visibility: hidden;
            transform: translateX(100%) translateY(-50%);
            transition: opacity ease $animTime $animTime;
        }
    }

    .logo-title {
        background-image: url(../img/logo_edenred_icon.png);
        background-size: contain;
        background-repeat: no-repeat;
        height: 30px;
        width: 30px;
        margin: auto;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition: background-position ease 0.3s;

        .icon {
            stroke: $white;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            margin: -8px auto 0;
            transform: rotate(90deg) translateY(-80px);
            transition: transform ease $animTime;
        }

    }

    &:not(.expanded) {

        &:hover {
            .logo-title {
                background-position: -80px;

                .icon {
                    transform: rotate(90deg) translateX(0);
                }
            }
        }

        .burger {

            &-link {
                width: 30px;

                &.connected-link {
                    padding: 0;

                    > .icon {
                        margin: auto;
                    }
                }
            }

        }

        .search input {
            opacity: 0;
        }

    }

    &.expanded {

        .logo-big {
            opacity: 1;
            visibility: visible;
            transform: translateX(0) translateY(-50%);
        }

        .logo-title {
            background-position: -80px;
            position: absolute;
            top: 50%;
            left: 10px;
            margin-top: -14px;

            .icon {
                transform: rotate(-90deg) translateX(0);
            }
        }

        .burger {

            &-link {
                width: 100%;
            }

            &-content {
                opacity: 1;
                visibility: visible;
            }
        }

    }

    .search {
        height: 1.5rem;
        width: 190px;
        margin: 0;

        input {
            color: $white;
            padding-left: 26px;
            opacity: 1;
            transition: opacity ease $animTime;
        }

        .icon-search {
            stroke: $white;
            max-height: 1rem;
            max-width: 1rem;
        }

        &.active .icon-search {
            transform: translateX(calc(190px - 15px)) translateY(-50%);
        }

        .close {
            margin: 0;

            .icon {
                margin: 0;
                stroke-width: 0.5px;
            }
        }
    }

}
