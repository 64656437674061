.nav-toggle {

    &-wrapper {
        border-bottom: 1px solid $greyBorder;
        overflow: hidden;

        h2 {
            margin-top:31px;
        }
    }

    &-anchor {
        animation-duration: 400ms;

        &[aria-hidden="true"] {
            display: none;
        }

    }

    &-link {
        font-family: $montserrat;
        font-size: 0.9375rem;
        position: relative;
        display: inline-block;
        margin: 0 15px;
        overflow: hidden;
        max-width: 306px;
        cursor: pointer;

        &:before {
            content: '';
            background-color: $bodyColor;
            height: 3px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            transform: translateY(100%);
            transition: transform ease $animTime;
        }

        &:not([aria-active="true"]):hover {
            &:before {
                background-color: lighten($bodyColor, 30%);
                transform: translateY(0);
            }
        }

        &[aria-active="true"] {

            .sprite-wire-transfer {
                background-position: -15px -255px;
            }

            .sprite-cb-hollow {
                background-position: -15px -67px;
            }

        }

        .icon {
            display: inline-block;
            vertical-align: middle;
            // max-height: 25px;
            max-width: 100%;
            // margin-right: 8px;

            // &-cb-hollow {

            //     svg, path, g{
            //         all: inherit;
            //         fill: inherit;
            //         stroke: inherit;
            //     }

            //     .contour {
            //         stroke: $bodyColor;
            //     }
            //     .logo {
            //         fill: $bodyColor;
            //     }

            // }

            &.icon-balloons {
                max-width: 24px;
                max-height: 24px;
                margin-bottom: 6px;
                float: left;
            }
        }

        .inner-link {
            display: table-cell;
            vertical-align: middle;
            padding-left: 10px;
            padding-bottom: 6px;
        }

        &[aria-active="true"] {
            font-weight: bold;
            
            &:before {
                transform: translateY(0);
            }
        }

    }

}
