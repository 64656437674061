.main-header {
    height: $headerHeight;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 995;

    &.header-connected {
        background-color: $white;
        border-bottom: 1px solid rgba($grey, 0.35);
        padding: 10px $tunnelPadding;
        position: relative;
        overflow: hidden;

        .logo-title {
            background-image: url('../img/edenred.gif');
            border-right: 1px solid rgba($greyDarker, 0.2);
        }
    }

    .connected-link {
        font-size: 0.875rem;
        color: $white;
        padding-right: 20px;

        .icon {
            margin-right: 5px;
            height: 0.725rem;
            width: 0.725rem;
        }
    }

    &-logo {
        height: 100%;
        display: flex;
        flex-grow: 1;
        margin: auto 0;
        justify-content: space-between;

        .logo-title {
            height: 99%;
            position: relative;
            background-image: url('../img/logo_ticket_restaurant_small.png');
            background-position: center;
            background-size: contain;
            background-size: 70%;
            background-repeat: no-repeat;
            margin: 0;
            width: 180px;

            > img {
                vertical-align: middle;
            }
        }

        .burger-button {
            flex: 0 0 55px;
            position: relative;
            display: flex;
            background-color: $greyLight;

            .icon-burger {
                margin: auto;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 1px;
                height: 100%;
                background-color: rgba($greyDarker, 0.2);
            }
        }
    }

    &-nav {
        height: 40px;
        margin: auto 5px auto auto;

        .button-action {
            width: 42px;
            margin: 0 5px;
            background: white;
            border: 1px solid #22353b;

            .icon-inline {
                svg {
                    stroke:#22353b;
                }
            }
        }

        .menu li {
            padding-left: 10px;
        }
    }
}
@media (max-width: 768px){
    .main-header {
        background: white;
        
        &-logo {
            width: 60%;
        }

        &-nav {
            width: 40%;
        }
    }
}

.title-link img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

$subHeaderColor: #f0efeb;

.header-intro {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 997;

    .nav-exc {
        height: 30px;
        background-color: $subHeaderColor;
        display: flex;
        text-align: right;
        width: 100%;
        // padding-right: 65px;
        justify-content: flex-end;

        p {
            text-align: right;
            line-height: 2.3;
            font-size: 0.875rem;
            color: #323232;
            margin: 0;
            // color: rgba(17, 32, 76, 0.5);
        }

        a {
            line-height: 32px;
            padding: 0 15px;
            position: relative;
            color: rgb(34, 53, 59);

            // &:last-child {
            //     line-height: 28px;
            // }

            &:not(:last-child):before {
                content: "";
                width: 1px;
                height: 20px;
                position: absolute;
                top: 5px;
                right: 0;
                background-color: #e8ecf3;
            }
        }
    }

    @media(max-width: 768px){
        .nav-exc {
            justify-content: center;
            height: 70px;
            text-align: center;

            p {
                width:100%;
                text-align: center;
            }

            a {
                width: 25%;
                color: #1482c5;
            }
        }
    }

    &-ruban {
        height: 90px;
        padding: 0 65px;
        background-color: $white;
        display: flex;
        justify-content: space-between;

        h1 {
            position: relative;
            font-size: 1.375rem;
            text-align: left;
            color:$blueTitle;
            margin: 0;
            width: 100%;
            /*padding-right: 190px;*/
            
            color: $blueTitle;

            .smaller {
                font-weight: 300;
                font-size: inherit;
                line-height: inherit;
            }
            
        }
        .halfCard {
            height: 142px;
            content: "";
            background-image: url('../img/ticket-resto-carte-nfc-small-rotated.png');
            background-size: cover;
            background-position: center;
            position: absolute;
            bottom: -77px;
            right: 1px;
            width: 196px;
        }
        @media(max-width: 1200px){
            .halfCard {
                display: none;
            }
        }

        .column {
            position: relative;
            margin: auto;

            /* Uncommment to reactivate the tip down on logo like on ticketsrestaurants.com */
            // &:first-child:before {
            //     content: "";
            //     background-image: url(../img/tip-down.png);
            //     background-size: cover;
            //     background-position: center;
            //     position: absolute;
            //     bottom: -54px;
            //     left: 70px;
            //     width: 64px;
            //     height: 32px;
            // }

        }
        .logo-block {
            height: 100%;
            // margin: auto 15px auto auto;
            overflow: hidden;
            display: flex;
            align-items: center;
        }
    }

    @media(max-width: 768px){
        &-ruban {
            padding: 0;
            max-height: 74px;

            p {
                max-width:160px;
                margin: 0 auto;
                max-height: 70px;
            }

            .logo-block {
                padding: 0;
                margin: 0 auto;
                max-height: 70px;

                h1 {
                    font-size: 0.9375rem;
                    text-align: left;
                    padding: 0;
                }
            }
        }
    }

    .header-link {
        height: 90px;
        line-height: 90px;
    }

    .logo-tr {
        height: auto;
        vertical-align: middle;
        line-height: normal;
    }
}

.header {

    &-breadcrumb {
        display: flex;
        align-items: center;
        margin: auto auto auto 0;

        &-link {
            display: flex;
            align-items: center;

            + .icon-chevron {
                fill: $greyText;
                transform: rotate(-90deg);
                vertical-align: middle;
                transform-origin: center;
                margin-right: 0.5rem;
                max-width: 0.7rem;
                // max-width: 18px;
                // margin: -2px 25px 0 -25px;
            }

            > * {
                margin-right: 8px;
            }

            .icon-home {
                margin-top: -3px;
            }
        }

        .separator {
            max-width: 0.75rem;
            max-height: 0.75rem;
        }
    }

    &-connection {
        height: 110px;
        display: block;
        position: relative;
        margin-top: -60px;
        z-index: 999;

        .header-breadcrumb {
            background-color: $white;
            width: 100%;
            height: 80px;
            padding: 0 15px;
            align-items: center;
            justify-content: space-between;

            .button {
                margin-bottom: 0;
            }
        }
    }

    &-drawer-trigger {
        height: 30px;
        background-color: $blueConnected;
        color: $white;
        font-size: 0.6875rem;
        letter-spacing: 0.125rem;
        font-weight: 300;
        display: table;
        width: 100%;

        .icon {
            vertical-align: middle;
            max-height: 0.6875rem;
            max-width: 0.6875rem;
            fill: $white;
        }

        .button-label {
            vertical-align: middle;
            text-transform: uppercase;
            margin: 0 10px;
            
            em {font-weight: 500;}
        }
    }

    &-drawer {
        background-color: $white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        transform: translateY(-100%);
        transition: transform 350ms ease-in-out;

        &.open {
            transform: translateY(0);
        }

    }

    &-actions {
        position: relative;
        display: flex;

        .actions-wrapper-item:not(:last-of-type) {
            margin-right: 1rem;
        }
    }

}

.drawer {

    &-content {
        padding: 10px 10px 0 10px;
        position: relative;
    }

    &-bottom {
        @extend .header-drawer-trigger;
        text-align: center;
        width: calc(100% + 20px);
        margin: 42px -10px 0;
        position: relative;

        .encoche {
            height: 20px;
            background-color: $blueConnected;
            width: 40px;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 0;
            transform: translateY(-100%);

            &:before,
            &:after {
                content: "";
                height: 40px;
                background-color: white;
                width: 40px;
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: -20px;
                border-radius: 20px;
            }

            &:before {
                left: -40px;
            }

            &:after {
                right: -40px;
            }
        }

        .icon {
            height: 100% !important;
            max-height: 100%;
            text-align: center;
        }
    }

}

.logo-cma {
    height: 45px;
    margin: 8px 15px 0 0;
}