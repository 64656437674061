.form-control {
    @include form-element;
    height: $inputHeight;
    line-height: 1;
    padding: 0.5rem 15px;
    margin-bottom: 0 !important;
}

$placeholderColor: rgba(188, 188, 188, 1);

#{text-inputs()},
textarea {
    border-radius: 3.125rem;
    padding: 0 2.1875rem 0 2.1875rem;
    border-color: rgba(215, 215, 215, 1);
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0);
    transition: all ease 0.35s;

    @include placeholder {
        color: $placeholderColor !important;
        transition: color ease 0.35s;
    }

    &:hover {
        border-color: rgba(196, 196, 196, 1);
    }

    &:focus {
        border-color: rgba(170, 196, 227, 1);
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.2);

        &::-webkit-input-placeholder {
            color: rgba($greyDarker, 0) !important;
        }

        &:-moz-placeholder {
            color: rgba($greyDarker, 0) !important;
        }

        /* Firefox > 19 */
        &::-moz-placeholder {
            color: rgba($greyDarker, 0) !important;
        }

        /* Internet Explorer 10 */
        &:-ms-input-placeholder {
            color: rgba($greyDarker, 0) !important;
        }
    }
}

.faded-mask {
    position: relative;

    &:before {
        content: "";
        background-color: rgba($white, 0.6);
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }
}

#{text-inputs()},
select {
  height: $inputHeight;

  &.small-box {
    font-size: 1.125rem;
    text-align: center;
    padding: 0;
    margin: 0 auto 1rem;
    width: 5.625rem;
    border-radius: 50px;
    min-width: 8.75rem;
  }

}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    background-color: $white !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset;

    &:focus {
        background-color: $white !important;
        -webkit-box-shadow: 0 0 0px 1000px white inset;
    }

    &:disabled,
    &[disabled],
    &[readonly] {
        background-color: $input-background-disabled;
        -webkit-box-shadow: 0 0 0px 1000px $input-background-disabled inset;
    }
}

.form {

  &-small {
    margin: 0 auto;
    max-width: 320px;
  }

  &-medium {
    margin: 0 auto;
    max-width: 400px;
  }

  &-intermediate {
      margin: 0 auto;
      max-width: 475px;
  }
}

.label-actions {

  height: 100%;
  position: relative;
  margin-left: 5px;
  display: inline-block;
  width: 1em;

  &__sort {
    height: 0.75rem;
    outline: none;
    position: absolute;
    width: 0.75rem;

    /*&:first-child {
      top: calc(50% - 22px);
    }*/

    &:last-child {
      top: calc(50% - 22px);
    }

    .icon {
      width: 100%;
    }

  }

}

.picker {

  &__input[readonly] {
    background-color: $white;
  }

}

.select-wrapper {
  position: relative;

  select {
    font-weight: 400;
    padding-left: 25px;
    margin: 0;

    > option {
      font-weight: 300;
      font-size: 0.875em;
    }

    + label {
      height: 10px;
      width: 10px;
      position: absolute;
      right: 20px;
      transform-origin: center;
      transition: transform ease 0.35s;
      top: 50%;
      transform: translateY(-50%);

      &:before,
      &:after {
        height: 2px;
        background-color: $actionsColor;
        top: 50%;
        content: "";
        position: absolute;
        margin-top: -1px;
        width: 7px;
        transform-origin: center;
      }

      &:before {
        right: 3.5px;
        transform: rotate(50deg);
      }

      &:after {
        right: 0;
        transform: rotate(-50deg);
      }

    }

    &:focus {

      + label {
        transform: translateY(-50%) rotate(180deg);
      }

    }

  }
}

.select {

  &-item {
    color: rgb(97, 97, 97);
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.625rem;
    display: block;
    cursor: pointer;
  }

}

fieldset {
    margin: 2em 0;

    &.names, legend {
        font-weight: 700;
        margin: 1em 0;
        font-size: 1rem;
    }
    .InformationComplementaireLivraison {

      input {
        max-width:300px;
        margin: 0 auto;
      }
    }

    input {
      max-width:300px;
      margin: 0 auto;
    }
}

.submit {

    &-wrapper {
        width: 100%;
        // margin-top: $marginTopDefault;
        margin-top: 1.375rem;
        display: flex;
        justify-content: center;

        /*.button:not(:last-child) {
            margin-right: 10px;
        }*/
    }

    &-form {
      padding-left: 15px;

      &:first-of-type {
        padding-top: 15px;
      }

      .button {
        width: 100%;
      }
    }

}

@mixin baseCustomInput($radius) {
  // height: 1.625rem;
  display: inline-block;
  padding-left: 30px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin-right: 15px;
  margin-left: 0;

  &:before,
  &:after {
    content: "";
    background-color: #ccc;
    position: absolute;
    top: 0.2em;
    border-radius: $radius;
    transition: all 0.25s ease-in-out;
  }
}

.radio {

  &-wrapper {
    position: relative;

    &.button-fake-radio label {
        // display: none;
    }

  }

  &-input {
    display: none;
  }

  &-input + &-label{

    @include baseCustomInput(50%);

    &:before {
      height: 18px;
      width: 18px;
      left: 0;
      opacity: 0;
    //   transform: scale(0);
      background-color: $green;
      transform: scale(0.4);
      transition: opacity ease $animTime;
    }

    &:after {
      width: 18px;
      height: 18px;
      display: block;
      position: absolute;
      left: 0;
      border: 2px solid #ccc;
      background-color: transparent;
      transition: all 0.25s ease-in-out;
      z-index: 2;
    }

    &:hover {

      &:before {
        opacity: 0.5;
      }

    }

  }

  &-input:checked + &-label {

    &:before {
      opacity: 1;
    }

    &:after {
        border-color: $green;
    }

  }

}

.checkbox {

  &-wrapper {
    position: relative;

    &.has-error label {
        border-color: $errorColor;
    }
  }

  &-input {
    display: none;
  }

  &-input + &-label{
    @include baseCustomInput($borderRadius);
    margin-left: 0;

    &:before { /* Ckeck mark */
      content: "";
      position: absolute;
      left: 2px;
      top: 7px;
      display: table;
      height: 8px;
      width: 14px;
      border-width: 3px;
      border-style: solid;
      border-top: 0;
      border-right: 0;
      border-color: $white;
      background-color: transparent;
      transform: rotate(-45deg) scale(0);
      transition: transform ease 0.2s;
    }

    &:after { /* Ckeck canvas */
      left: 0;
      width: 18px;
      height: 18px;
      background-color: $white;
      border: 1px solid $greyMedium;
      z-index: -1;
      transition: none;
    }
  }

  &-input:checked + &-label {

    &:before {
      opacity: 1;
      transform-origin: center;
      transform: rotate(-45deg) scale(0.9);
    }

    &:after {
      left: 0;
      width: 18px;
      height: 18px;
      background-color: $primaryColor;
      border-color: transparent;
      z-index: -1;
    }

  }

}


/*======================================
  Selectric
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  height: $inputHeight;
  border: 0.063rem solid rgb(215, 215, 215);
  position: relative;
  margin-bottom: 1rem;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-radius: 3.125rem;
  padding: 0 2.1875rem 0 2.1875rem;

  .label {
    height: $inputHeight;
    background: none;
    line-height: $inputHeight;
    font-size: 0.875rem;
    color: #494949;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0 0 0 15px;
    display: block;
  }

  .button {

    // reset foundation .button styles
    padding: 0;
    border: none;
    border-radius: 0;
    margin: 0;
    font-size: 1em;
    background-color: transparent;
    color: transparent;

    // styles of real custom select button
    height: 10px;
    width: 10px;
    position: absolute;
    right: 20px;
    transform-origin: center;
    top: 50%;
    transform: translateY(-50%);
    transition: transform ease 0.35s;

    &::before,
    &::after {
      height: 2px;
      background-color: $actionsColor;
      top: 50%;
      content: "";
      position: absolute;
      margin-top: -1px;
      width: 7px;
      transform-origin: center;
      transition: transform ease 0.35s;
    }

    &::before {
      right: 3px;
      transform: rotate(50deg);
    }

    &::after {
      right: 0;
      transform: rotate(-50deg);
    }
  }

  &-open {
    z-index: 9999;

    .selectric {
        border-bottom-color: transparent;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;

      .button {
        transform: translateY(-50%) rotate(180deg);
      }

      &-items {
        display: block;
      }

    }
  }

  &-disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  }

  &-hide-select {
    position: relative;
    overflow: hidden;
    width: 0;
    height: 0;

    select {
      position: absolute;
      left: -100%;
      display: none;
    }
  }

  &-input {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    outline: none !important;
    border: none !important;
    *font: 0/0 a !important;
    background: none !important;
  }

  &-temp-show {
    position: absolute !important;
    visibility: hidden !important;
    display: block !important;
  }

  /* Items box */
  &-items {
    background-color: whitesmoke;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    border-left: 1px solid rgb(215, 215, 215);
    border-right: 1px solid rgb(215, 215, 215);
    border-bottom: 1px solid rgb(215, 215, 215);
    z-index: -1;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;

    ul, li {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 0.875rem;
      min-height: 20px;
    }

    li {
      height: 1.875rem;
      font-weight: 300;
      line-height: 1.875rem;
      padding: 0 0 0 15px;
      display: block;
      cursor: pointer;

      &:first-of-type {
        display: none;
      }

      &:hover {
        background: #F0F0F0;
        color: #444;
      }

      &.selected {
        background: #EFEFEF;
        color: #444;
      }
    }

    .disabled {
      filter: alpha(opacity=50);
      opacity: 0.5;
      cursor: default !important;
      background: none !important;
      color: #666 !important;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
    }

    .selectric-scroll {
      height: 100%;
      overflow: auto;
    }

    .selected-group {

      li {
        padding-left: 25px;
      }

      &.disabled {
        li {
          filter: alpha(opacity=100);
          opacity: 1;
        }
      }
      .selectric-group-label {
        background: none;
        font-weight: bold;
        padding-left: 10px;
        color: #444;
        cursor: default;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
      }
    }
  }

  &-above {
    .selectric-items {
      top: auto;
      bottom: 100%;
    }
  }
}

.input-wrapper {
    position: relative;

    #siret {
      max-width: 300px;
      margin: 0 auto;
    }
}

.filter-highlight {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 15px;
}
