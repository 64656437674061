.tooltip {
$tooltipGap: 26px;

  &-component {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }

  &-icon {
    height: 1rem;
    line-height: 1rem;
    color: $actionsColor;
    text-align: center;
    width: 1rem;
    display: inline-block;
    border: 1px solid $actionsColor;
    opacity: 0.6;
    margin: 0 4px;
    border-radius: 50%;
    transition: opacity ease $animTime;

    span {
      cursor: default;
      font-family: Georgia, sans-serif;
      font-size: 0.625rem;
      font-weight: 700;
      display: inline-block;
      line-height: 1;
      vertical-align: middle;
    }

    &:hover {
      opacity: 1;
    }
  }

  &-content {
    background-color: $blueDark;
    text-align: left;
    font-size: 0.875rem;
    color: $white;
    padding: 1rem;
    min-width: 300px;
    margin: 0;
    position: absolute;
    visibility: visible;
    opacity: 1;
    border-radius: $borderRadius;
    z-index: 9999;
    transition: opacity ease 0.35s;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
    }

    &.to-right {
      left: $tooltipGap;
      top: 50%;
      transform: translateY(-50%);

      &:before {
        border-width: 8px 12px 8px 0;
        border-color: transparent $blueDark transparent transparent;
        left: -6px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.to-left {
      right: $tooltipGap;
      top: 50%;
      transform: translateY(-50%);

      &:before {
        border-width: 8px 0 8px 12px;
        border-color: transparent transparent transparent $blueDark;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.to-top {
      bottom: $tooltipGap;
      margin: 0 0 0 50%;
      transform: translateX(-50%);

      &:before {
        border-width: 12px 8px 0 8px;
        border-color: $blueDark transparent transparent transparent;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -6px;
      }
    }

    &.to-bottom {
      top: $tooltipGap;
      margin: 0 0 0 50%;
      transform: translateX(-50%);

      &:before {
        border-width: 0 8px 12px 8px;
        border-color: transparent transparent $blueDark transparent;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: -6px;
      }
    }

  }

}
