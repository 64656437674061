.lt-ie10 {

    .table-new-benef .add-benef {
        display: inline-block;
    }

    .actions-on-edit a svg {
        padding: 1rem 0;
    }

    .main-header {
        display: table;

        &-logo,
        &-nav {
            display: table-cell;
            vertical-align: middle;
        }

        &-nav {
            text-align: right;
        }

        .logo-title {
            max-width: 200px;
        }
    }

    .summary {

        &-row:not(.total) {
            clear: both;
            &:before,
            &:after {
                content: " ";
                display: table;
                clear: both;
            }

            .summary-label {
                float: left;
                line-height: 2.2;
            }

            .summary-price {
                float: right;
            }
        }

    }

    .sk-spinner {
        background-image: url(../img/loading-ie.gif);
        background-position: center;

        &.sk-spinner-wave {
            width: 250px; 
        }

        [class^="sk-rect"] {
            display: none;
        }
    }

    .logo-block > h1 {
        float: left;
        margin-top: 35px;
        text-align: left;
        max-width: 600px;
    }

    .nav-exc {
        p, a {
            display: inline-block;
        }
    }
    
    .paiement {

        .summary-row.total {
            width: 100% !important;
            margin: 0 !important;
        }

        .summary-label {
            text-align: left;
            margin-top: 18px;
            width: 100%;
        }

        .nav-toggle-wrapper {
            line-height: 0.5;
        }

        .nav-toggle-link {
            display: inline-block;

            .inner-link {
                height: 24px;
            }
        } 

    }

    .simulation-recap > column {
        display: table;
        vertical-align: baseline;
    }

}