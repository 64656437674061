.faq {

  &-wrapper {
    transition: all ease 0.8s;

    &.closed {
      transform: translate3d(-100%, 0, 0);
    }

    > .button:first-of-type {
      display: block;
      margin: 20px auto;
    }
  }

  &-header {
      transform: translateY(80px);
      transition: all ease 0.5s;

    &.move-up {
    //   transform: translate3d(0, -50%, 0);
      transform: translateY(0);

    }
  }

  &-questions {
    border-radius: 2px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    overflow: hidden;
    max-width: 550px;
    z-index: 1;
    transform: translateY(80px);
  }

  &-links {
    padding: 1em;

    &:nth-child(3n+1) {
      border: 2px solid rgba(193, 70, 46, 0.5);
      width: 100%;
    }

    &:nth-child(3n+2) {
      border-right: 2px solid rgba(193, 70, 46, 0.5);
      border-left: 2px solid rgba(193, 70, 46, 0.5);

      &:nth-child(even) {
        flex-grow: 1;

        + a {
          flex-shrink: 1;
        }
      }

      &:nth-child(odd) {
        flex-shrink: 1;

        + a {
          flex-grow: 1;
        }
      }

      + a {
        border-right: 2px solid rgba(193, 70, 46, 0.5);
      }
    }
  }

  &-answer {
    // top: 140px;
    // left: 0;
    // right: 0;
    // position: absolute;
    margin: 20px auto;
    max-width: 600px;
    transform: translate3d(0, 100%, 0);
    visibility: hidden;
    opacity: 0;
    z-index: 0;
    transition: all ease 0.8s;

    &.open {
        visibility: visible;
        opacity: 1;
        transform: translate3d(0, -276px, 0);
      // animation: 5s slideOutDown ease;
    }

    // &.ng-hide-remove {
    // }
    //   animation: 5s slideInUp ease;
  }

  &-article {
    text-align: left;
    padding: 1.625em;
    border-radius: 2px;
    background-color: rgba(230, 96, 70, 1);
    margin-bottom: 20px;
  }
}
