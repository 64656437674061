.block-error {
    background-color: $alertColor;
    font-size: 1.125rem;
    padding: 20px;
    color: $white;
    display: block;
    border-radius: $borderRadius;
}

.has-error {

    input,
    select,
    textarea,
    &.select-wrapper {
        border: 1px solid rgba($alertColor, 0.7);
    }

    .radio-label,
    .checkbox-label {
        &:after {
            border-color: rgba($alertColor, 0.7);
        }
    }
}

// #errorBox {
//     margin: 0 auto 45px;
// }

.validation {

    &-invalid {
        // word-break: break-all;
        background-color: $alertColor;
        font-size: 0.875rem;
        font-weight: 400;
        padding: 9px 20px;
        font-size: 1em;
        color: $white;
        opacity: 1;
        transition: opacity ease $animTime;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    &-information {
        background-color: #6686b7;
        margin-top: 0px;
        font-size: 0.875rem;
        font-weight: 400;
        padding: 9px 20px;
        font-size: 1em;
        color: white;
        opacity: 1;
        transition: opacity ease 0.2s;
    }

    &-alert {
        background-color: $infoColor;
        position: relative;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        .validation-content {
            order: 2;
        }

        .button {
            background: none;
            font-size: 1rem;
            font-weight: 600;

            &.ternary {
                &.close {
                    order: 1;
                    margin-bottom: 0;
                    padding: 8px;
                    font-size: 26px;
                }
                &.add {
                    order: 3;
                }
                &.delete {
                    order: 4
                }
            }
        }
    }

    &-content {
        font-size: 0.875rem;
        font-weight: 400;
        padding: 16px 20px;
        font-size: 1em;
        color: $white;
        opacity: 1;
        width: 100%;
        text-align: center;
    }
}

.button-separator {
    border-right: 1px solid white;
    height: 50px;
}

//
// .ng-invalid {
//
//     .form-error {
//         display: block;
//         opacity: 1;
//
//         .validation {
//             &-invalid {
//                 color: $white;
//             }
//         }
//     }
//
// }

@media (max-width: 768px) {
    .validation-alert {
        .button {
            width: 60%;
        }
    }
}